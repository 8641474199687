// src/components/dashboard/Donor/DonorSidebar.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import { FaHome, FaUser,FaUsers, FaFileContract, FaGavel, FaMoneyBill } from 'react-icons/fa';

const DonorSidebar = () => {
 
    const DonorLinks = [
        { path: "/", label: "Home", icon: <FaHome /> },
        // { path: "/dashboard/donor/profile", label: "Profile", icon: <FaUser /> },
        { path: "/dashboard/donor/people", label: "People", icon: <FaUsers /> },
        { path: "/dashboard/donor/tenders", label: "Tenders", icon: <FaFileContract /> },
        { path: "/dashboard/donor/bidding", label: "Bidding", icon: <FaGavel /> },
        { path: "/dashboard/donor/payments", label: "Payments", icon: <FaMoneyBill /> },
      ];
  return (
    <div className="w-64 bg-gray-800 text-white min-h-screen p-4">
      <h2 className="text-xl font-bold mb-6">Donor Menu</h2>
      <nav className="space-y-4">
        {DonorLinks.map(({ path, label, icon }) => (
          <Link
            key={path}
            to={path}
            className="flex items-center space-x-3 hover:text-blue-400 transition duration-200"
          >
            {icon}
            <span>{label}</span>
          </Link>
        ))}
      </nav>
    </div>
  );
};

export default DonorSidebar;
