import React, { useEffect, useState } from "react";
import SupplierSidebar from "./supplierSidebar";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { handleAPIRequest } from "../../../api/api";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import {
  Modal,
  Button,
  Pagination,
  TextField,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";

const SupplierPayments = () => {
  const [payments, setPayments] = useState([]);
  const [filteredPayments, setFilteredPayments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [newPayment, setNewPayment] = useState({
    amount: "",
    paymentType: "manual",
    documentTag: "",
    paymentDocument: null,
  });

  const token = useSelector((state) => state.auth.token);
  const itemsPerPage = 10;

  const fetchPayments = async () => {
    try {
      const response = await handleAPIRequest(
        "get",
        "show-payments",
        null,
        null,
        token
      );
      if (response?.payments) {
        setPayments(response.payments);
        setFilteredPayments(response.payments);
      }
    } catch (error) {
      console.log("Failed to fetch payments.");
    }
  };

  useEffect(() => {
    fetchPayments();
  }, []);

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    setFilteredPayments(
      payments.filter((payment) =>
        [
          "user.contact_person",
          "document.document_tag",
          "amount",
          "status",
          "payment_type",
        ].some((field) =>
          payment[field]?.toString().toLowerCase().includes(query)
        )
      )
    );
  };

  const resetModalFields = () => {
    setNewPayment({
      amount: "",
      paymentType: "manual",
      documentTag: "",
      paymentDocument: null,
    });
  };

  const handleOpenModal = () => {
    resetModalFields();
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    resetModalFields();
    setIsModalOpen(false);
  };

  const handleAddPayment = async () => {
    const formData = new FormData();
    formData.append("payment_type", newPayment.paymentType);
    formData.append("amount", newPayment.amount);
    formData.append("document_tag", newPayment.documentTag);
    formData.append("payment_document", newPayment.paymentDocument);

    try {
      const response = await handleAPIRequest(
        "post",
        "add-payment",
        formData,
        null,
        token
      );
      if (response?.message === "Payment and document added successfully!") {
        toast.success("Payment added successfully!");
        fetchPayments();
        handleCloseModal(); // Close modal after adding payment
      } else {
        toast.error("Failed to add payment. Please try again.");
      }
    } catch (error) {
      toast.error("Error adding payment.");
    }
  };

  const handleChangePage = (event, value) => setCurrentPage(value);

  const currentItems = filteredPayments.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="flex min-h-screen flex-col">
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
      <div className="flex flex-1">
        <SupplierSidebar />

        <div className="flex-1 flex flex-col">
          <Navbar />

          <div className="p-4 flex-1">
            <h2 className="text-2xl font-bold mb-4">Supplier Payments</h2>

            <div className="flex justify-between mb-4">
              <TextField
                label="Search Payments"
                variant="outlined"
                value={searchQuery}
                onChange={handleSearch}
                className="w-full md:w-1/3"
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenModal}
              >
                Add Payment
              </Button>
            </div>

            <div className="overflow-auto">
              {/* <table className="w-full bg-white rounded-lg shadow-md">
                <thead>
                  <tr className="bg-gray-200 text-gray-700">
                    <th className="py-2 px-4 border">Payment ID</th>
                    <th className="py-2 px-4 border">User Name</th>
                    <th className="py-2 px-4 border">Document Tag</th>
                    <th className="py-2 px-4 border">Amount Paid</th>
                    <th className="py-2 px-4 border">Document</th>
                    <th className="py-2 px-4 border">Status</th>
                    <th className="py-2 px-4 border">Payment Type</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((payment) => (
                    <tr key={payment.id} className="text-gray-700">
                      <td className="py-2 px-4 border">{payment.id}</td>
                      <td className="py-2 px-4 border">{payment.user.contact_person}</td>
                      <td className="py-2 px-4 border">{payment.document.document_tag}</td>
                      <td className="py-2 px-4 border">{payment.amount}</td>
                      <td className="py-2 px-4 border">
                        <a
                          href={payment.document.document_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500 hover:underline"
                        >
                          Click here to view document
                        </a>
                      </td>
                      <td className="py-2 px-4 border">{payment.status}</td>
                      <td className="py-2 px-4 border">{payment.payment_type}</td>
                    </tr>
                  ))}
                </tbody>
              </table> */}
              <table className="w-full bg-white rounded-lg shadow-md">
                <thead>
                  <tr className="bg-gray-200 text-gray-700">
                    <th className="py-2 px-4 border">Payment ID</th>
                    <th className="py-2 px-4 border">User Name</th>
                    <th className="py-2 px-4 border">Document Tag</th>
                    <th className="py-2 px-4 border">Amount Paid</th>
                    <th className="py-2 px-4 border">Document</th>
                    <th className="py-2 px-4 border">Status</th>
                    <th className="py-2 px-4 border">Payment Type</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.length > 0 ? (
                    currentItems.map((payment) => (
                      <tr key={payment.id} className="text-gray-700">
                        <td className="py-2 px-4 border">{payment.id}</td>
                        <td className="py-2 px-4 border">
                          {payment.user.contact_person}
                        </td>
                        <td className="py-2 px-4 border">
                          {payment.document.document_tag}
                        </td>
                        <td className="py-2 px-4 border">{payment.amount}</td>
                        <td className="py-2 px-4 border">
                          <a
                            href={payment.document.document_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-500 hover:underline"
                          >
                            Click here to view document
                          </a>
                        </td>
                        <td className="py-2 px-4 border">{payment.status}</td>
                        <td className="py-2 px-4 border">
                          {payment.payment_type}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="py-2 px-4 border text-center" colSpan="7">
                        No payments made so far
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div className="flex justify-center mt-4">
              <Pagination
                count={Math.ceil(filteredPayments.length / itemsPerPage)}
                page={currentPage}
                onChange={handleChangePage}
                color="primary"
              />
            </div>
          </div>

          <Footer />
        </div>
      </div>

      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg w-1/2">
            <h3 className="text-lg font-semibold mb-4">Add Payment</h3>

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Amount
              </label>
              <TextField
                variant="outlined"
                type="number"
                fullWidth
                value={newPayment.amount}
                onChange={(e) =>
                  setNewPayment({ ...newPayment, amount: e.target.value })
                }
              />
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Payment Type
              </label>
              <FormControl fullWidth variant="outlined">
                <Select
                  value={newPayment.paymentType}
                  onChange={(e) =>
                    setNewPayment({
                      ...newPayment,
                      paymentType: e.target.value,
                    })
                  }
                  label="Payment Type"
                >
                  <MenuItem value="manual">Manual</MenuItem>
                  <MenuItem value="online">Online</MenuItem>
                  <MenuItem value="easypaisa">EasyPaisa</MenuItem>
                  <MenuItem value="bank">Bank Transfer</MenuItem>
                  <MenuItem value="paypal">Paypal</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Document Tag
              </label>
              <TextField
                variant="outlined"
                fullWidth
                value={newPayment.documentTag}
                onChange={(e) =>
                  setNewPayment({ ...newPayment, documentTag: e.target.value })
                }
              />
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Upload Payment Document
              </label>
              <TextField
                variant="outlined"
                type="file"
                fullWidth
                onChange={(e) =>
                  setNewPayment({
                    ...newPayment,
                    paymentDocument: e.target.files[0],
                  })
                }
              />
            </div>

            <div className="mt-6 flex justify-between">
              <Button
                variant="contained"
                onClick={handleCloseModal}
                className="mr-4"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddPayment}
              >
                Add Payment
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SupplierPayments;
