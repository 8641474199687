import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link to navigate to /pricing

const StepRole = ({ nextStep, prevStep, updateRole }) => {
  const [selectedRole, setSelectedRole] = useState('');

  const handleRoleSelection = (role) => {
    setSelectedRole(role);
    updateRole(role); // Update the role in parent state
  };

  return (
    <div>
      <h3 className="text-xl font-semibold mb-4">Select Your Role</h3>
      
      <div className="flex flex-col sm:flex-row justify-between gap-4 mb-4">
        {/* Supplier */}
        <div
          onClick={() => handleRoleSelection('supplier')}
          className={`p-4 rounded w-full cursor-pointer text-center ${selectedRole === 'supplier' ? 'bg-gray-600 text-white' : 'bg-gray-200 hover:bg-gray-300 text-black'}`}
        >
          <h4 className="font-bold">Supplier</h4>
          <p className="text-sm">Monthly 1000PKR</p>
          <p className="text-sm font-semibold">{selectedRole === 'supplier' ? 'Selected' : ''}</p>
        </div>

        {/* NGO */}
        <div
          onClick={() => handleRoleSelection('company')}
          className={`p-4 rounded w-full cursor-pointer text-center ${selectedRole === 'company' ? 'bg-gray-600 text-white' : 'bg-gray-200 hover:bg-gray-300 text-black'}`}
        >
          <h4 className="font-bold">NGO</h4>
          <p className="text-sm">800 Euro Annually</p>
          <p className="text-sm font-semibold">{selectedRole === 'company' ? 'Selected' : ''}</p>
        </div>

        {/* Donor */}
        <div
          onClick={() => handleRoleSelection('donor')}
          className={`p-4 rounded w-full cursor-pointer text-center ${selectedRole === 'donor' ? 'bg-gray-600 text-white' : 'bg-gray-200 hover:bg-gray-300 text-black'}`}
        >
          <h4 className="font-bold">Donor</h4>
          <p className="text-sm">1500 Euro Annually</p>
          <p className="text-sm font-semibold">{selectedRole === 'donor' ? 'Selected' : ''}</p>
        </div>
      </div>

      {/* Link to pricing */}
      <div className="text-center mb-4">
        <Link to="/pricing" className="text-blue-500 hover:underline">
          View Detailed Price Plan
        </Link>
      </div>

      <div className="flex justify-between">
        <button onClick={prevStep} className="bg-green-500 text-white px-4 py-2 rounded">
          Back
        </button>
        <button
          onClick={nextStep}
          className={`bg-green-500 text-white px-4 py-2 rounded ${!selectedRole ? 'opacity-50 cursor-not-allowed' : ''}`}
          disabled={!selectedRole} // Disable the button if no role is selected
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default StepRole;
