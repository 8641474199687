import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { AiFillEdit, AiFillDelete } from "react-icons/ai"; // Import icons from react-icons
import { toast } from "react-toastify"; // Import toast
import Navbar from "../Navbar"; // Assuming Navbar is in the components folder
import Sidebar from "./CompanySidebar"; // Assuming Sidebar is in the components folder
import Footer from "../Footer"; // Assuming Footer is in the components folder
import { handleAPIRequest } from "../../../api/api"; // Import your handleAPIRequest function
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CompanyStaff = () => {
  const [staffMembers, setStaffMembers] = useState([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [newStaff, setNewStaff] = useState({
    name: "",
    contact_no: "",
    address: "",
    email: "",
    country: "",
    password: "", // Added password field
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const itemsPerPage = 10;
  const token = useSelector((state) => state.auth.token);

  // Fetch staff data
  const fetchStaffMembers = async () => {
    try {
      const response = await handleAPIRequest(
        "get",
        "get-company-staff",
        null,
        null,
        token
      );
      if (response && response.status) {
        setStaffMembers(response.company_users);
      } else {
        Swal.fire(
          "Error",
          response.message || "Failed to fetch staff data",
          "error"
        );
      }
    } catch (error) {
      console.error("Error fetching staff members:", error);
      Swal.fire(
        "Error",
        "An error occurred while fetching staff data",
        "error"
      );
    }
  };

  useEffect(() => {
    fetchStaffMembers();
  }, [token]);

  // Toggle Add Modal
  const toggleAddModal = () => {
    setIsAddModalOpen(!isAddModalOpen);
    if (isAddModalOpen) {
      resetNewStaff(); // Reset new staff state when closing
    }
  };

  // Toggle Edit Modal
  const toggleEditModal = (staff) => {
    setSelectedStaff(staff);
    setNewStaff({
      name: staff.contact_person,
      contact_no: staff.contact_no,
      address: staff.address,
      email: staff.email,
      country: staff.country,
      password: "", // Password can be empty when editing
    });
    setIsEditModalOpen(!isEditModalOpen);
  };

  const resetNewStaff = () => {
    setNewStaff({
      name: "",
      contact_no: "",
      address: "",
      email: "",
      country: "",
      password: "",
    });
  };

  // Handle Add Staff
  const handleAddStaff = async (e) => {
    e.preventDefault();
    try {
      const response = await handleAPIRequest(
        "post",
        "create-company-staff",
        newStaff,
        null,
        token
      );
      if (response && response.status) {
        toast.success("Staff member added successfully!");
        resetNewStaff(); // Reset new staff state
        toggleAddModal();
        await fetchStaffMembers();
      } else {
        toast.error(response.message || "Failed to add staff member");
      }
    } catch (error) {
      console.error("Error adding staff member:", error);
      toast.error("An error occurred while adding staff member");
    }
  };

  // Handle Edit Staff
  const handleEditStaff = async (e) => {
    e.preventDefault();
    try {
      const response = await handleAPIRequest(
        "post",
        `edit-company-staff/${selectedStaff.id}`,
        newStaff,
        null,
        token
      );
      if (response && response.status) {
        toast.success("Staff member updated successfully!");
        setIsEditModalOpen(false);
        await fetchStaffMembers();
      } else {
        toast.error(response.message || "Failed to update staff member");
      }
    } catch (error) {
      console.error("Error editing staff member:", error);
      toast.error("An error occurred while editing staff member");
    }
  };

  // Handle Delete Staff
  const handleDeleteStaff = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const formData = new FormData();
          formData.append("company_user_id", id);

          const response = await handleAPIRequest(
            "post",
            "delete-company-staff",
            formData,
            null,
            token
          );
          if (response && response.status) {
            toast.success("Staff member deleted successfully!");
            await fetchStaffMembers();
          } else {
            toast.error(response.message || "Failed to delete staff member");
          }
        } catch (error) {
          console.error("Error deleting staff member:", error);
          toast.error("An error occurred while deleting staff member");
        }
      }
    });
  };

  // Pagination logic
  const startIndex = (currentPage - 1) * itemsPerPage;
  const filteredStaff = staffMembers.filter(staff =>
    staff.contact_person.toLowerCase().includes(searchTerm.toLowerCase()) || 
    staff.email.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const displayedStaff = filteredStaff.slice(
    startIndex,
    startIndex + itemsPerPage
  );
  const pageCount = Math.ceil(filteredStaff.length / itemsPerPage);

  return (
    <div className="flex h-screen">
      <Sidebar /> {/* Sidebar component */}
      <div className="flex flex-col w-full">
        <Navbar /> {/* Navbar component */}
        <div className="container mx-auto p-4">
          <h2 className="text-2xl font-bold mb-4">Company Staff Members</h2>
          <div className="mb-4 flex justify-between items-center">
            <button
              onClick={toggleAddModal}
              className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition duration-200"
            >
              Add Staff Member
            </button>
            {/* Search Input */}
            <input
              type="text"
              placeholder="Search by Name or Email"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="border border-gray-300 p-2 rounded-md w-1/4"
            />
          </div>
          <table className="min-w-full bg-white border border-gray-300">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b">ID</th>
                <th className="py-2 px-4 border-b">Name</th>
                <th className="py-2 px-4 border-b">Email</th>
                <th className="py-2 px-4 border-b">Contact No</th>
                <th className="py-2 px-4 border-b">Address</th>
                <th className="py-2 px-4 border-b">Country</th>
                <th className="py-2 px-4 border-b">Verified</th>
                <th className="py-2 px-4 border-b">Created At</th>
                <th className="py-2 px-4 border-b">Actions</th>
              </tr>
            </thead>
            <tbody>
              {displayedStaff.map((staff) => (
                <tr key={staff.id}>
                  <td className="py-2 px-4 border-b">{staff.id}</td>
                  <td className="py-2 px-4 border-b">{staff.contact_person}</td>
                  <td className="py-2 px-4 border-b">{staff.email}</td>
                  <td className="py-2 px-4 border-b">{staff.contact_no}</td>
                  <td className="py-2 px-4 border-b">{staff.address}</td>
                  <td className="py-2 px-4 border-b">{staff.country}</td>
                  <td className="py-2 px-4 border">
                    {staff.status === "active" ? (
                      <span className="inline-block px-2 py-1 rounded-full text-white bg-green-500">
                        Yes
                      </span>
                    ) : (
                      <span className="inline-block px-2 py-1 rounded-full text-white bg-red-500">
                        No
                      </span>
                    )}
                  </td>
                  <td className="py-2 px-4 border-b">{new Date(staff.created_at).toLocaleString()}</td>
                  <td className="py-2 px-4 border-b flex space-x-2">
                    <button onClick={() => toggleEditModal(staff)}>
                      <AiFillEdit className="text-blue-600 hover:text-blue-700" />
                    </button>
                    <button onClick={() => handleDeleteStaff(staff.id)}>
                      <AiFillDelete className="text-red-600 hover:text-red-700" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* Pagination controls */}
          <div className="flex justify-between items-center mt-4">
            <button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              className="bg-gray-400 text-white px-4 py-2 rounded-md disabled:opacity-50"
            >
              Previous
            </button>
            <span>
              Page {currentPage} of {pageCount}
            </span>
            <button
              onClick={() => setCurrentPage((prev) => Math.min(prev + 1, pageCount))}
              disabled={currentPage === pageCount}
              className="bg-gray-400 text-white px-4 py-2 rounded-md disabled:opacity-50"
            >
              Next
            </button>
          </div>
        </div>

        {/* Add Staff Modal */}
        {isAddModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg shadow-lg p-6 w-96">
              <h3 className="text-xl font-bold mb-4">Add Staff Member</h3>
              <form onSubmit={handleAddStaff}>
                <input
                  type="text"
                  placeholder="Name"
                  value={newStaff.name}
                  onChange={(e) => setNewStaff({ ...newStaff, name: e.target.value })}
                  className="border border-gray-300 p-2 rounded-md w-full mb-2"
                  required
                />
                <input
                  type="text"
                  placeholder="Contact No"
                  value={newStaff.contact_no}
                  onChange={(e) => setNewStaff({ ...newStaff, contact_no: e.target.value })}
                  className="border border-gray-300 p-2 rounded-md w-full mb-2"
                  required
                />
                <input
                  type="text"
                  placeholder="Address"
                  value={newStaff.address}
                  onChange={(e) => setNewStaff({ ...newStaff, address: e.target.value })}
                  className="border border-gray-300 p-2 rounded-md w-full mb-2"
                  required
                />
                <input
                  type="email"
                  placeholder="Email"
                  value={newStaff.email}
                  onChange={(e) => setNewStaff({ ...newStaff, email: e.target.value })}
                  className="border border-gray-300 p-2 rounded-md w-full mb-2"
                  required
                />
                <input
                  type="text"
                  placeholder="Country"
                  value={newStaff.country}
                  onChange={(e) => setNewStaff({ ...newStaff, country: e.target.value })}
                  className="border border-gray-300 p-2 rounded-md w-full mb-2"
                  required
                />
                <input
                  type="password"
                  placeholder="Password"
                  value={newStaff.password}
                  onChange={(e) => setNewStaff({ ...newStaff, password: e.target.value })}
                  className="border border-gray-300 p-2 rounded-md w-full mb-2"
                  required
                />
                <div className="flex justify-between mt-4">
                  <button
                    type="button"
                    onClick={toggleAddModal}
                    className="bg-gray-300 text-black px-4 py-2 rounded-md"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition duration-200"
                  >
                    Add Staff
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        {/* Edit Staff Modal */}
        {isEditModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg shadow-lg p-6 w-96">
              <h3 className="text-xl font-bold mb-4">Edit Staff Member</h3>
              <form onSubmit={handleEditStaff}>
                <input
                  type="text"
                  placeholder="Name"
                  value={newStaff.name}
                  onChange={(e) => setNewStaff({ ...newStaff, name: e.target.value })}
                  className="border border-gray-300 p-2 rounded-md w-full mb-2"
                  required
                />
                <input
                  type="text"
                  placeholder="Contact No"
                  value={newStaff.contact_no}
                  onChange={(e) => setNewStaff({ ...newStaff, contact_no: e.target.value })}
                  className="border border-gray-300 p-2 rounded-md w-full mb-2"
                  required
                />
                <input
                  type="text"
                  placeholder="Address"
                  value={newStaff.address}
                  onChange={(e) => setNewStaff({ ...newStaff, address: e.target.value })}
                  className="border border-gray-300 p-2 rounded-md w-full mb-2"
                  required
                />
                <input
                  type="email"
                  placeholder="Email"
                  value={newStaff.email}
                  onChange={(e) => setNewStaff({ ...newStaff, email: e.target.value })}
                  className="border border-gray-300 p-2 rounded-md w-full mb-2"
                  required
                />
                <input
                  type="text"
                  placeholder="Country"
                  value={newStaff.country}
                  onChange={(e) => setNewStaff({ ...newStaff, country: e.target.value })}
                  className="border border-gray-300 p-2 rounded-md w-full mb-2"
                  required
                />
                <input
                  type="password"
                  placeholder="Password (leave blank to keep current)"
                  value={newStaff.password}
                  onChange={(e) => setNewStaff({ ...newStaff, password: e.target.value })}
                  className="border border-gray-300 p-2 rounded-md w-full mb-2"
                />
                <div className="flex justify-between mt-4">
                  <button
                    type="button"
                    onClick={() => setIsEditModalOpen(false)}
                    className="bg-gray-300 text-black px-4 py-2 rounded-md"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition duration-200"
                  >
                    Update Staff
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default CompanyStaff;
