// src/components/dashboard/Navbar.jsx
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/authSlice';
import { persistor } from '../../redux/store';
import { handleAPIRequest } from "../../api/api"; // Update path based on your setup
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
const Navbar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const handleLogout = async () => {
    try {
      const response = await handleAPIRequest("post", "logout", null, null, token);
      
      if (response) { // Check if the response status is successful
        dispatch(logout()); // Clear the authentication state
        persistor.purge(); // Clear the persisted storage
        navigate("/login"); // Redirect to login page
        toast.success("Successfully logged out");
      } else {
        toast.error("Failed to log out");
      }
    } catch (error) {
      console.error("Logout error:", error);
      toast.error("An error occurred during logout");
    }
  };
  return (
    
    <nav className="bg-gray-800 text-white p-4 flex justify-between items-center">
     
      <h1 className="text-xl font-bold">Dashboard</h1>
      <div className="flex items-center">
        <button onClick={toggleSidebar} className="lg:hidden text-white">
          {/* Hamburger Icon */}
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
          </svg>
        </button>
        <button
        onClick={handleLogout}
        className="bg-red-500 px-4 py-2 rounded text-white"
      >
        Logout
      </button>
      </div>
  
    </nav>
  );
};

export default Navbar;
