import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaLinkedinIn } from 'react-icons/fa'; // FontAwesome icons
import { FaXTwitter } from "react-icons/fa6";
const Footer = () => {
  return (
    <footer className="bg-gray-800 p-6 text-white">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center">
        {/* Navbar Links */}
        <div className="mb-4 md:mb-0">
          <ul className="flex flex-col md:flex-row space-y-2 md:space-x-6 md:space-y-0">
            <li>
              <Link to="/" className="hover:text-gray-400">Home</Link>
            </li>
            <li>
              <Link to="/about-us" className="hover:text-gray-400">About Us</Link>
            </li>
            <li>
              <Link to="/pricing" className="hover:text-gray-400">Pricing</Link>
            </li>
          </ul>
        </div>

        {/* Contact Information */}
        <div className="text-center md:text-right mb-6 md:mb-0">
          <h2 className="text-xl font-semibold mb-2">Contact Us</h2>
          <p className="text-sm mb-2">For Sales: <strong>sales@ngostenders.com</strong></p>
          <p className="text-sm mb-2">For Support: <strong>support@ngostenders.com</strong></p>
          <p className="text-sm">For 24/7 Support: <strong>+92 312 1111 002</strong></p>
        </div>
      </div>

      {/* Social Media Icons Section */}
      <div className="mt-6 flex justify-center space-x-6">
        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" className="hover:text-blue-600">
          <FaFacebookF size={24} />
        </a>
        <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" className="hover:text-blue-700">
          <FaLinkedinIn size={24} />
        </a>
        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="hover:text-blue-400">
          <FaXTwitter size={24} />
        </a>
      </div>

      {/* Footer Bottom */}
      <div className="mt-6 border-t border-gray-700 pt-4 text-center">
        <p>&copy; 2024 Bilcono. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
