// src/components/dashboard/superAdmin/SuperAdminDashboard.jsx
import React from 'react';
import Navbar from '../Navbar'; // Adjust the import path as needed
import SuperAdminSidebar from './superAdminSidebar';
import Footer from '../Footer'; // Adjust the import path as needed
import { Line } from 'react-chartjs-2'; // Import Chart.js
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement } from 'chart.js'; // Import necessary components for Chart.js
import 'chart.js/auto'; // Auto-register chart types
ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement);
const SuperAdminDashboard = () => {
  const data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June'],
    datasets: [
      {
        label: 'Verified New Users',
        data: [10, 20, 30, 25, 35, 40],
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        fill: true,
      },
    ],
  };
  return (
    <div className="flex min-h-screen">
      {/* Sidebar */}
      <SuperAdminSidebar />

      {/* Main Content */}
      <div className="flex-1 flex flex-col">
        <Navbar />
        <main className="flex-grow p-6">
          <h2 className="text-2xl font-bold mb-4">Admin Dashboard</h2>
          <p className="mb-6">Welcome to the Admin Dashboard! Manage your admin details, tenders, and bids here.</p>

          {/* Cards Section */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
            {/* Total Users Card */}
            <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
              <h3 className="text-lg font-semibold mb-2">Total Users</h3>
              <p className="text-2xl font-bold">1,250</p>
            </div>

            {/* Total Tenders Card */}
            <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
              <h3 className="text-lg font-semibold mb-2">Total Tenders</h3>
              <p className="text-2xl font-bold">300</p>
            </div>

            {/* Total Revenue Card */}
            <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
              <h3 className="text-lg font-semibold mb-2">Total Revenue</h3>
              <p className="text-2xl font-bold">$50,000</p>
            </div>
          </div>

          {/* Graph Section */}
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-lg font-semibold mb-4">Verified New Users</h3>
            <div className="h-64"> {/* Set a fixed height for the graph */}
              <Line data={data} options={{ responsive: true, maintainAspectRatio: false }} height={250} />
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default SuperAdminDashboard;
