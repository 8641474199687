// src/components/dashboard/superAdmin/SuperAdminSidebar.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import { FaHome, FaUser, FaUsers, FaFileContract, FaGavel, FaMoneyBill,FaHourglassStart } from 'react-icons/fa';

const SuperAdminSidebar = () => {
  const adminLinks = [
    { path: "/", label: "Home", icon: <FaHome /> },
    { path: "/dashboard/admin", label: "Dashboard", icon: <FaHourglassStart /> },
    { path: "/dashboard/admin/profile", label: "Profile", icon: <FaUser /> },
    { path: "/dashboard/admin/people", label: "People", icon: <FaUsers /> },
    { path: "/dashboard/admin/tenders", label: "Tenders", icon: <FaFileContract /> },
    { path: "/dashboard/admin/bidding", label: "Bidding", icon: <FaGavel /> },
    { path: "/dashboard/admin/payments", label: "Payments", icon: <FaMoneyBill /> },
  ];

  return (
    <div className="w-64 bg-gray-800 text-white min-h-screen p-4">
      <h2 className="text-xl font-bold mb-6">Super Admin Menu</h2>
      <nav className="space-y-4">
        {adminLinks.map(({ path, label, icon }) => (
          <Link
            key={path}
            to={path}
            className="flex items-center space-x-3 hover:text-blue-400 transition duration-200"
          >
            {icon}
            <span>{label}</span>
          </Link>
        ))}
      </nav>
    </div>
  );
};

export default SuperAdminSidebar;
