import React, { useState, useEffect } from "react";
import { Modal, Button, Box, Typography, CircularProgress } from "@mui/material";
import { handleAPIRequest } from "../../api/api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "./bidDetails.css"; // Import custom CSS for styling

const BidDetails = ({ bidId, open, handleClose }) => {
  const [bidDetails, setBidDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const token = useSelector((state) => state.auth.token);

  const fetchBidDetails = async () => {
    try {
      setLoading(true);
      const response = await handleAPIRequest(
        "get",
        `get-bid/${bidId}`,
        null,
        null,
        token
      );
      if (response?.status) {
        setBidDetails(response);
      } else {
        toast.error("Failed to fetch bid details.");
      }
    } catch (error) {
      setError("An error occurred while fetching bid details.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (bidId && open) {
      fetchBidDetails();
    }
  }, [bidId, open]);

  const handleDownload = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = url.split("/").pop();
    link.click();
  };

  if (loading) {
    return (
      <Modal open={open} onClose={handleClose}>
        <Box className="modal-container">
          <CircularProgress />
        </Box>
      </Modal>
    );
  }

  if (error) {
    return (
      <Modal open={open} onClose={handleClose}>
        <Box className="modal-container">
          <Typography variant="h6" color="error">
            {error}
          </Typography>
        </Box>
      </Modal>
    );
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className="modal-container">
        <Box className="modal-content">
          <Typography variant="h5" gutterBottom className="modal-title">
            Bid Details
          </Typography>
          <Typography variant="h6">Tender: {bidDetails?.bid?.tender?.tender_title}</Typography>
          <Typography variant="body1">
            <strong>Tender Description:</strong> {bidDetails?.bid?.tender?.tender_description}
          </Typography>
          
          {/* Replacing bid amount with Main Document Download Button */}
          <Box mb={2} className="document-item">
            <Typography variant="body1">
              <strong>Main Document:</strong>
            </Typography>
            {bidDetails?.bid?.main_doc && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleDownload(bidDetails.bid.main_doc)}
              >
                Download Main Document
              </Button>
            )}
          </Box>

          <Typography variant="body1">
            <strong>Bid Status:</strong> {bidDetails?.bid?.status}
          </Typography>
          <Typography variant="body1">
            <strong>Submitted On:</strong> {new Date(bidDetails?.bid?.created_at).toLocaleDateString()}
          </Typography>

          <Typography variant="h6" gutterBottom>
            User Information
          </Typography>
          <Typography variant="body1">
            <strong>Contact Person:</strong> {bidDetails?.bid?.user?.contact_person}
          </Typography>
          <Typography variant="body1">
            <strong>Email:</strong> {bidDetails?.bid?.user?.email}
          </Typography>
          <Typography variant="body1">
            <strong>Contact Number:</strong> {bidDetails?.bid?.user?.contact_no}
          </Typography>
          <Typography variant="body1">
            <strong>Address:</strong> {bidDetails?.bid?.user?.address}
          </Typography>

          <Typography variant="h6" gutterBottom>
            Documents
          </Typography>
          <div className="documents-list">
            {bidDetails?.documents?.map((doc) => (
              <Box key={doc.id} mb={2} className="document-item">
                <Typography variant="body2">{doc.document_tag}</Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleDownload(doc.document_url)}
                >
                  Download {doc.document_tag}
                </Button>
              </Box>
            ))}
          </div>

          <div className="modal-actions">
            <Button onClick={handleClose} variant="contained" color="primary">
              Close
            </Button>
          </div>
        </Box>
      </Box>
    </Modal>
  );
};

export default BidDetails;
