import React, { useState, useEffect } from 'react';

const StepDocuments = ({ nextStep, prevStep, updateDocuments, existingDocument, updateTag ,updateAmount }) => {
  const [document, setDocument] = useState(existingDocument || null); // For a single document
  const [tag, setTag] = useState('');
  const [amount, setAmount] = useState('');
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0]; // Get the first selected file
    if (selectedFile) {
      setDocument(selectedFile);
      updateDocuments([selectedFile]); // Update parent state with an array
    }
  };

  const handleRemoveDocument = () => {
    setDocument(null);
    setTag(''); // Clear the tag when the document is removed
    updateDocuments([]); // Update parent state to reflect the removal
    updateTag(''); // Clear the tag in parent state
  };

  useEffect(() => {
    // Update local document state if the existing document prop changes
    if (existingDocument) {
      setDocument(existingDocument);
    }
    
  }, [existingDocument]);

  // Update the tag state and call the updateTag function
  const handleTagChange = (e) => {
    const newTag = e.target.value;
    setTag(newTag);
    updateTag(newTag); // Call the function to update the tag in parent
  };

  const handleAmountChange = (e) => {
    const newAmount = e.target.value;
    setAmount(newAmount);
    updateAmount(newAmount); // Call the function to update the tag in parent
  };
  return (
    <div className="bg-white p-6 rounded shadow-md">
      <h3 className="text-2xl font-semibold mb-4">Payment Documents</h3>
      {!document ? ( // Show the file input if no document is uploaded
        <input
          type="file"
          accept=".png, .jpg, .jpeg, .pdf" // Restrict file types
          onChange={handleFileChange}
          className="mb-4 p-2 border rounded w-full"
          required
        />
      ) : (
        <div className="mb-4">
          <label className="block mb-1">Uploaded Document: {document.name}</label>
          <input
            type="text"
            value={tag}
            onChange={handleTagChange} // Use the new handler
            className="p-2 border rounded w-full mb-2"
            placeholder="Add a tag for this document"
          />
           <input
            type="text"
            value={amount}
            onChange={handleAmountChange} // Use the new handler
            className="p-2 border rounded w-full mb-2"
            placeholder="Add amount of the payment you completed"
          />
          <div className="mt-2">
            <a
              href={URL.createObjectURL(document)} // Create a URL for the uploaded file
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 underline"
            >
              View Uploaded Document
            </a>
          </div>
          <button
            onClick={handleRemoveDocument}
            className="mt-2 text-red-500 hover:underline"
          >
            Remove Document
          </button>
        </div>
      )}
      <div className="flex justify-between mt-4">
        <button onClick={prevStep} className="bg-green-500 text-white px-4 py-2 rounded">
          Back
        </button>
        <button
          onClick={nextStep}
          className="bg-green-500 text-white px-4 py-2 rounded"
          disabled={!document}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default StepDocuments;
