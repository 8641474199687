import React, { useState } from 'react';
import { TextField, IconButton, InputAdornment, Button, Box } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loginSuccess } from '../../redux/authSlice';  // Import the action
import loginImage from '../../assets/images/login.jpg';
import { handleAPIRequest } from "../../api/api";
import { toast } from 'react-toastify'; // Import toast
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();  // Redux dispatch

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleSignUp = () => navigate('/signup');

  const handleLogin = async () => {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);

    try {
      const response = await handleAPIRequest("post", "login", formData);
      console.log("API Response:", response);

      const { Role, token, User, affiliatedDetails } = response;

      // Save token, role, and user in Redux state
      dispatch(loginSuccess({
        token,
        role: Role,
        user: User,
        affiliatedDetails,
      }));

      // Redirect based on role
      if (Role === 'super_admin') {
        navigate('/dashboard/admin');
      }
      else if  (Role === 'admin') {
        navigate('/dashboard/admin');
      } 
      else if (Role === 'company_owner') {
        navigate(`/dashboard/company`);
      }
      else if (Role === 'company_user') {
        navigate(`/dashboard/company`);
      }
      else if (Role === 'supplier_owner') {
        navigate(`/dashboard/supplier`);
      }
      else if (Role === 'donor_owner') {
        navigate(`/dashboard/donor`);
      }
     else {
        toast.error('Unauthorized role'); // Show toast error
      }
    } catch (error) {
      console.error("Login error:", error);
      toast.error('Login failed. Please check your credentials.'); // Show toast error
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(${loginImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: '20px',
      }}
    >
      <Box
        component="form"
        sx={{
          backgroundColor: 'white',
          padding: '40px',
          borderRadius: '8px',
          boxShadow: 3,
          width: '100%',
          maxWidth: '400px',
        }}
      >
        <h1 className="text-3xl font-bold text-center mb-6">Login</h1>

        <div className="mb-4">
          <TextField
            label="Email"
            type="email"
            fullWidth
            variant="outlined"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div className="mb-6">
          <TextField
            label="Password"
            type={showPassword ? 'text' : 'password'}
            fullWidth
            variant="outlined"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>

        <div className="flex flex-col space-y-4 mt-6">
          <Button variant="contained" fullWidth onClick={handleLogin}>
            Login
          </Button>
        </div>

        <div className="flex justify-between mt-4">
          <Button variant="text" color="primary">
            Forgot Password?
          </Button>
          <Button variant="text" color="primary" onClick={handleSignUp}>
            Sign Up
          </Button>
        </div>

        <p className="mt-4 text-center">
          <Link to="/" className="text-blue-500 hover:underline">
            Go back to Home
          </Link>
        </p>
      </Box>
      <ToastContainer />
    </Box>
  );
};

export default Login;
