import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const RedirectRoute = ({ element }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const role = useSelector((state) => state.auth.role);

  if (isAuthenticated) {
    // Redirect based on the role
    if (role === 'super_admin') {
      return <Navigate to="/dashboard/admin" />;
    }else if (role === 'admin') {
      return <Navigate to="/dashboard/admin" />;
    } else if (role === 'company_owner') {
      return <Navigate to="/dashboard/company" />;
    } else if (role === 'company_user') {
      return <Navigate to="/dashboard/company" />;
    } else if (role === 'supplier_owner') {
      return <Navigate to="/dashboard/supplier" />;
    } else if (role === 'donor_owner') {
      return <Navigate to="/dashboard/donor" />;
    }
  }

  return element;
};

export default RedirectRoute;
