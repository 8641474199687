import React, { useState } from "react";
import { Link } from "react-router-dom";
import { handleAPIRequest } from "../../../api/api";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  FaHome,
  FaInfoCircle,
  FaUser,
  FaBars,
  FaTimes,
  FaCaretDown,
  FaClipboardCheck,
  FaBook,
} from "react-icons/fa";
import { IoMdPricetags } from "react-icons/io";
import navbarLogo from "../../../assets/images/navbarLogo.jpg";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../redux/authSlice";
import { persistor } from "../../../redux/store";

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { isAuthenticated, user, role } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleDashboardRoute = () => {
    if (role === "super_admin") {
      navigate("/dashboard/admin");
    } else if (role === "admin") {
      navigate("/dashboard/admin");
    } else if (role === "company_owner") {
      navigate(`/dashboard/company`);
    } else if (role === "company_user") {
      navigate(`/dashboard/company`);
    } else if (role === "supplier_owner") {
      navigate(`/dashboard/supplier`);
    } else if (role === "donor_owner") {
      navigate(`/dashboard/donor`);
    } else {
      toast.error("Unauthorized role");
    }
  };

  const handleLogout = async () => {
    try {
      const response = await handleAPIRequest(
        "post",
        "logout",
        null,
        null,
        token
      );
      if (response) {
        dispatch(logout());
        persistor.purge();
        navigate("/login");
        toast.success("Successfully logged out");
      } else {
        toast.error("Failed to log out");
      }
    } catch (error) {
      console.error("Logout error:", error);
      toast.error("An error occurred during logout");
    }
  };

  return (
    <nav className="bg-blue-600 p-4 shadow-md">
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo and App Name */}
        <div className="flex items-center">
          <img
            src={navbarLogo}
            alt="Logo"
            className="rounded-full mr-4"
            style={{ height: "64px", width: "108px" }}
          />
          <h1 className="text-2xl font-bold text-white md:text-xl">
            Welcome to NGO Tenders
          </h1>
        </div>

        {/* Desktop Menu Links */}
        <div className="hidden md:flex space-x-6">
          <Link
            to="/"
            className="bg-green-500 text-white flex items-center space-x-2 hover:bg-green-600 transition duration-300 px-4 py-2 rounded-md"
          >
            <FaHome className="mr-1" /> <span>Home</span>
          </Link>
          <Link
            to="/guide"
            className="bg-green-500 text-white flex items-center space-x-2 hover:bg-green-600 transition duration-300 px-4 py-2 rounded-md"
          >
            <FaBook className="mr-1" /> <span>Guide</span>
          </Link>
          <Link
            to="/pricing"
            className="bg-green-500 text-white flex items-center space-x-2 hover:bg-green-600 transition duration-300 px-4 py-2 rounded-md"
          >
            <IoMdPricetags className="mr-1" /> <span>Price Plans</span>
          </Link>

          {isAuthenticated && (
            <Link
              to="https://itemscatalogue.redcross.int/"
              className="bg-green-500 text-white flex items-center space-x-2 hover:bg-green-600 transition duration-300 px-4 py-2 rounded-md"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaClipboardCheck className="mr-1" />{" "}
              <span>IFRC Items Specifications</span>
            </Link>
          )}

          <Link
            to="/about-us"
            className="bg-green-500 text-white flex items-center space-x-2 hover:bg-green-600 transition duration-300 px-4 py-2 rounded-md"
          >
            <FaInfoCircle className="mr-1" /> <span>About Us</span>
          </Link>

          {/* Show username if authenticated */}
          {isAuthenticated ? (
            <div className="relative">
              <button
                onClick={toggleDropdown}
                className="bg-green-500 text-white flex items-center space-x-2 hover:bg-green-600 transition duration-300 px-4 py-2 rounded-md"
              >
                <span className="hidden md:block">
                  {user?.contact_person || "User"}
                </span>
                <FaCaretDown />
              </button>
              {isDropdownOpen && (
                <div className="absolute right-0 bg-white text-black shadow-lg mt-2 rounded-lg p-2">
                  <button
                    onClick={handleDashboardRoute}
                    className="bg-green-500 text-white block px-4 py-2 hover:bg-green-600 rounded-md mb-2"
                  >
                    Dashboard
                  </button>
                  <button
                    onClick={handleLogout}
                    className="bg-green-500 text-white block w-full text-left px-4 py-2 hover:bg-green-600 rounded-md"
                  >
                    Logout
                  </button>
                </div>
              )}
            </div>
          ) : (
            <>
              <Link
                to="/login"
                className="bg-green-500 text-white flex items-center space-x-2 hover:bg-green-600 transition duration-300 border-2 border-white px-4 py-2 rounded-full"
              >
                <FaUser className="mr-1" /> <span>Login</span>
              </Link>
              <Link
                to="/signup"
                className="bg-green-500 text-white flex items-center space-x-2 hover:bg-green-600 transition duration-300 border-2 border-white px-4 py-2 rounded-full"
              >
                <FaUser className="mr-1" /> <span>Signup</span>
              </Link>
            </>
          )}
        </div>

        {/* Mobile Menu Toggle Button */}
        <div className="md:hidden">
          <button
            onClick={toggleMobileMenu}
            className="bg-green-500 text-white px-4 py-2 rounded-md"
          >
            {isMobileMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="md:hidden bg-blue-600 p-4">
          <Link
            to="/"
            className="bg-green-500 mb-2 text-white block py-2 hover:bg-green-600 transition duration-300 px-4 rounded-md"
            onClick={toggleMobileMenu}
          >
            Home
          </Link>
          {isAuthenticated && (
            <Link
              to="/specifications"
              className="bg-green-500 mb-2 text-white block py-2 hover:bg-green-600 transition duration-300 px-4 rounded-md"
              onClick={toggleMobileMenu}
            >
              Specifications
            </Link>
          )}
          <Link
            to="/about-us"
            className="bg-green-500 mb-2 text-white block py-2 hover:bg-green-600 transition duration-300 px-4 rounded-md"
            onClick={toggleMobileMenu}
          >
            About Us
          </Link>
          {isAuthenticated ? (
            <>
              <Link
                onClick={() => {
                  handleDashboardRoute();
                  toggleMobileMenu();
                }}
                className="bg-green-500 mb-2 text-white block py-2 hover:bg-green-600 transition duration-300 px-4 rounded-md"
              >
                Dashboard
              </Link>
              <button
                onClick={handleLogout}
                className="bg-green-500 mb-2 text-white block w-full text-left py-2 hover:bg-green-600 transition duration-300 px-4 rounded-md"
              >
                Logout
              </button>
            </>
          ) : (
            <>
              <Link
                to="/login"
                className="bg-green-500 mb-2 text-white block py-2 hover:bg-green-600 transition duration-300 px-4 rounded-md"
                onClick={toggleMobileMenu}
              >
                Login
              </Link>
              <Link
                to="/signup"
                className="bg-green-500 mb-2 text-white block py-2 hover:bg-green-600 transition duration-300 px-4 rounded-md"
                onClick={toggleMobileMenu}
              >
                Signup
              </Link>
            </>
          )}
        </div>
      )}
    </nav>
  );
};

export default Navbar;
