import axios from 'axios';
import { getServerBaseUrl } from './BASE_URL';
import { toast } from 'react-toastify';

export const handleAPIRequest = async (
  method,
  url,
  data = null,
  params = null,
  token = null 
) => {
  const baseURL = getServerBaseUrl();
  const headers = {
    ...(method === 'post' || method === 'put' ? { 'Content-Type': 'multipart/form-data' } : {}),
    ...(token ? { 'Authorization': `Bearer ${token}` } : {})
  };

  try {
    const response = await axios({
      method,
      url: `${baseURL}/${url}`,
      headers,
      data,
      params,
      timeout: 90000,
    });

    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || 'An error occurred';
    console.error(errorMessage, 'error');

    if (error.code === 'ECONNABORTED') {
      toast.error("Can't make request");
    } else {
      toast.error(errorMessage);
    }

    throw new Error(errorMessage);
  }
};


// import axios from "axios";
// // import { BASE_URL } from "../Api/BaseUrl";
// import { setAppLoading } from "../Store/Actions/Actions";
// import store from "../Store/Store";

// const BASE_URL = "https://brightspace.health/api/";

// export const handleAPIRequest = async (method, url, data, params, base) => {
//   const state = store.getState();
//   const token = localStorage.getItem("token");
//   // store.dispatch(setAppLoading(true));

//   let header = null;

//   if (method === "post" || method === "put") {
//     header = {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${state?.user?.token}`,
     
//     };
//   } else {
//     header = {
//       Authorization: `Bearer ${state?.user?.token}`,
    
//     };
//   }
//   try {
//     const response = await axios({
//       method: method,
//       url: base ? base : `${BASE_URL}${url}`,
//       headers: header,
//       data: data,
//       params: params,
//     });

//     // store.dispatch(setAppLoading(false));
//     return response.data;
//   } catch (error) {
//     console.error(error);
//     // store.dispatch(setAppLoading(false));

//     throw new Error(error);
//   }
// };



