import React, { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Ensure you have react-icons installed

const StepUser = ({ nextStep, userInfo, updateUserInfo }) => {
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility

  // Function to check if all fields are filled
  const isFormComplete = () => {
    return (
      userInfo.name &&
      userInfo.phone &&
      userInfo.email &&
      userInfo.password &&
      userInfo.address &&
      userInfo.country
    );
  };

  return (
    <div>
      <h3 className="text-xl font-semibold mb-4">User Information</h3>
      <input
        type="text"
        placeholder="Name"
        value={userInfo.name}
        onChange={updateUserInfo('name')}
        className="mb-4 p-2 border rounded w-full"
      />
      <input
        type="text"
        placeholder="Phone Number"
        value={userInfo.phone}
        onChange={updateUserInfo('phone')}
        className="mb-4 p-2 border rounded w-full"
      />
      <input
        type="email"
        placeholder="Email"
        value={userInfo.email}
        onChange={updateUserInfo('email')}
        className="mb-4 p-2 border rounded w-full"
      />
      <div className="relative mb-4">
        <input
          type={showPassword ? 'text' : 'password'}
          placeholder="Password"
          value={userInfo.password}
          onChange={updateUserInfo('password')}
          className="p-2 border rounded w-full"
        />
        <span 
          className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
          onClick={() => setShowPassword(!showPassword)} // Toggle password visibility
        >
          {showPassword ? <FaEyeSlash /> : <FaEye />}
        </span>
      </div>
      <input
        type="text"
        placeholder="Address"
        value={userInfo.address}
        onChange={updateUserInfo('address')}
        className="mb-4 p-2 border rounded w-full"
      />
      <input
        type="text"
        placeholder="Country"
        value={userInfo.country}
        onChange={updateUserInfo('country')}
        className="mb-4 p-2 border rounded w-full"
      />
      
      {/* Container for the Next button */}
      <div className="flex justify-end">
        <button 
          onClick={nextStep} 
          className={`bg-green-500 text-white px-4 py-2 rounded ${!isFormComplete() ? 'opacity-50 cursor-not-allowed' : ''}`}
          disabled={!isFormComplete()} // Disable button if form is not complete
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default StepUser;
