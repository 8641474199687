// src/components/dashboard/company/CompanySidebar.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import { FaHome, FaUser, FaFileContract, FaGavel, FaMoneyBill, FaHourglassStart } from 'react-icons/fa';

const CompanySidebar = () => {
  const companyLinks = [
    { path: "/", label: "Home", icon: <FaHome /> },
    { path: "/dashboard/company", label: "Dashboard", icon: <FaHourglassStart /> },
    { path: "/dashboard/company/profile", label: "Profile", icon: <FaUser /> },
    { path: "/dashboard/company/users", label: "Users", icon: <FaUser /> },
    { path: "/dashboard/company/tenders", label: "Tenders", icon: <FaFileContract /> },
    { path: "/dashboard/company/bidding", label: "Bidding", icon: <FaGavel /> },
    { path: "/dashboard/company/payments", label: "Payments", icon: <FaMoneyBill /> },
  ];

  return (
    <div className="w-64 bg-gray-800 text-white min-h-screen p-4">
      <h2 className="text-xl font-bold mb-6">Company Menu</h2>
      <nav className="space-y-4">
        {companyLinks.map(({ path, label, icon }) => (
          <Link
            key={path}
            to={path}
            className="flex items-center space-x-3 hover:text-blue-400 transition duration-200"
          >
            {icon}
            <span>{label}</span>
          </Link>
        ))}
      </nav>
    </div>
  );
};

export default CompanySidebar;
