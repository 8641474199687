import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const rolePaths = {
  super_admin: '/dashboard/admin',
  admin: '/dashboard/admin',
  company_owner: '/dashboard/company',
  company_user: '/dashboard/company',
  donor_owner: '/dashboard/donor',
  supplier_owner: '/dashboard/supplier',
};

const ProtectedRoute = ({ element: Element }) => {
  const { isAuthenticated, role } = useSelector((state) => state.auth);

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  // Check if the current path matches the user's allowed path
  const allowedPath = rolePaths[role];
  const currentPath = window.location.pathname;

  if (!currentPath.startsWith(allowedPath)) {
    // Redirect to the user's own dashboard if they try to access another role's path
    return <Navigate to={allowedPath} />;
  }

  return <Element />;
};

export default ProtectedRoute;
