// SearchedTenders.jsx
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { handleAPIRequest } from "../../api/api"; // Adjust path based on your project structure
import { useSelector, useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEye } from "react-icons/fa";
import dayjs from 'dayjs';
const SearchedTenders = ({ searchTerm, filter }) => {
  const { isAuthenticated, user, role } = useSelector((state) => state.auth);
  const [tenders, setTenders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Fetch tenders based on search term and filter when component mounts or props change
  useEffect(() => {
    const fetchSearchedTenders = async () => {
      try {
        const formData = new FormData();
        formData.append("term", searchTerm);
        formData.append("type", filter);

        const response = await handleAPIRequest("post", "tender/search", formData, null);
        console.log("API Response:", response);

        setTenders(Array.isArray(response.tenders) ? response.tenders : []);
      } catch (error) {
        console.error("Error fetching searched tenders:", error);
        setError("Failed to fetch tenders.");
      } finally {
        setLoading(false);
      }
    };

    fetchSearchedTenders();
  }, [searchTerm, filter]);

  const handleApplyNow = (tender) => {
    if (!isAuthenticated) {
      toast.error("Please login first to apply for this tender."); // Show error message
      return; // Exit the function if not authenticated
    }
    navigate("/single-tender", { state: { tender } });
  };
  if (loading) return <p>Loading tenders...</p>;
  if (error) return <p className="text-red-500">{error}</p>;

  return (
    <div className="w-full p-6 space-y-6">
    {tenders.length > 0 ? (
      tenders.map((tender) => (
        <div
          key={tender.id}
          className={`bg-white flex items-center p-4 rounded-lg shadow-lg border ${
            tender.featured === "yes" ? "border-gold-glow" : ""
          }`}
        >
          <img
            src={tender.company_document.document_url}
            alt={tender.tender_title}
            className="w-32 h-32 object-cover rounded-md mr-6"
          />
          <div className="flex-grow">
            <h3 className="text-xl font-bold">{tender.tender_title}</h3>
            <div className="flex flex-wrap gap-2 mt-2">
              <span className="text-sm text-white bg-green-500 px-2 py-1 rounded-md">
                Start Date: {dayjs(tender.tender_start_date).format("DD MMMM YYYY")}
              </span>
              <span className="text-sm text-white bg-green-500 px-2 py-1 rounded-md">
                End Date: {dayjs(tender.tender_end_date).format("DD MMMM YYYY")}
              </span>
              <span className="text-sm text-white bg-blue-500 px-2 py-1 rounded-md">
                NGO: {tender.companies.company_name}
              </span>
              <span
                className={`text-sm px-2 py-1 rounded-md bg-gray-500 text-white`}
              >
                {tender.bid_method === "Online" ? "Online Bid" : "Manual Bid"}
              </span>
            </div>
            <p className="mt-2">{tender.tender_description}</p>
          </div>
          <button
            onClick={() => handleApplyNow(tender)}
            className="mt-4 text-blue-600 hover:text-blue-700"
          >
            <FaEye size={24} />
          </button>
        </div>
      ))
    ) : (
      <p>No tenders found for the selected criteria.</p>
    )}
    <ToastContainer />
  </div>
  );
};

export default SearchedTenders;
