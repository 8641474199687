import React, { useEffect, useState } from "react";
import SuperAdminSidebar from "./superAdminSidebar";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { handleAPIRequest } from "../../../api/api";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { Modal, Button, Pagination, TextField } from "@mui/material";

const SuperAdminPayments = () => {
  const [payments, setPayments] = useState([]);
  const [filteredPayments, setFilteredPayments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [newStatus, setNewStatus] = useState("");

  const token = useSelector((state) => state.auth.token);
  const itemsPerPage = 10;

  const fetchPayments = async () => {
    try {
      const response = await handleAPIRequest("get", "show-a-payments", null, null, token);
      if (response?.payments) {
        setPayments(response.payments);
        setFilteredPayments(response.payments);
      }
    } catch (error) {
      console.error("Failed to fetch payments.");
    }
  };

  useEffect(() => {
    fetchPayments();
  }, []);

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    setFilteredPayments(
      payments.filter((payment) =>
        ["user.contact_person", "document.document_tag", "amount", "status", "payment_type"].some(
          (field) => payment[field]?.toString().toLowerCase().includes(query)
        )
      )
    );
  };

  const handleOpenModal = (payment) => {
    setSelectedPayment(payment);
    setNewStatus(payment.status);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedPayment(null);
    setIsModalOpen(false);
  };

  const handleUpdateStatus = async () => {
    if (!selectedPayment) return;

    const formData = { status: newStatus };

    try {
      await handleAPIRequest("post", `update-payment-status/${selectedPayment.id}`, formData, null, token);
      toast.success("Payment status updated successfully!");
      fetchPayments(); // Refresh the payments list
      handleCloseModal();
    } catch (error) {
      toast.error("Failed to update payment status.");
    }
  };

  const renderStatusColor = (status) => {
    switch (status) {
      case "pending":
        return <span className="text-yellow-500">{status}</span>;
      case "completed":
        return <span className="text-green-500">{status}</span>;
      case "failed":
        return <span className="text-red-500">{status}</span>;
      default:
        return <span>{status}</span>;
    }
  };

  const currentItems = filteredPayments.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="flex min-h-screen flex-col">
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
      <div className="flex flex-1">
        <SuperAdminSidebar />

        <div className="flex-1 flex flex-col">
          <Navbar />

          <div className="p-4 flex-1">
            <h2 className="text-2xl font-bold mb-4">Payments</h2>

            <div className="flex justify-between mb-4">
              <TextField
                label="Search Payments"
                variant="outlined"
                value={searchQuery}
                onChange={handleSearch}
                className="w-full md:w-1/3"
              />
            </div>

            <div className="overflow-auto">
              <table className="w-full bg-white rounded-lg shadow-md">
                <thead>
                  <tr className="bg-gray-200 text-gray-700">
                    <th className="py-2 px-4 border">Payment ID</th>
                    <th className="py-2 px-4 border">User Name</th>
                    <th className="py-2 px-4 border">Document Tag</th>
                    <th className="py-2 px-4 border">Amount Paid</th>
                    <th className="py-2 px-4 border">Document</th>
                    <th className="py-2 px-4 border">Status</th>
                    <th className="py-2 px-4 border">Payment Type</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.length > 0 ? (
                    currentItems.map((payment) => (
                      <tr key={payment.id} className="text-gray-700">
                        <td className="py-2 px-4 border">{payment.id}</td>
                        <td className="py-2 px-4 border">{payment.user.contact_person}</td>
                        <td className="py-2 px-4 border">{payment.document.document_tag}</td>
                        <td className="py-2 px-4 border">{payment.amount}</td>
                        <td className="py-2 px-4 border">
                          <a
                            href={payment.document.document_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-500 hover:underline"
                          >
                            Click here to view document
                          </a>
                        </td>
                        <td className="py-2 px-4 border cursor-pointer" onClick={() => handleOpenModal(payment)}>
                          {renderStatusColor(payment.status)}
                        </td>
                        <td className="py-2 px-4 border">{payment.payment_type}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="py-2 px-4 border text-center" colSpan="7">
                        No payments made so far
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div className="flex justify-center mt-4">
              <Pagination
                count={Math.ceil(filteredPayments.length / itemsPerPage)}
                page={currentPage}
                onChange={(event, value) => setCurrentPage(value)}
                color="primary"
              />
            </div>
          </div>

          <Footer />
        </div>
      </div>

      {/* Modal for Editing Payment Status */}
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <div className="bg-white p-8 rounded-md shadow-lg max-w-sm mx-auto mt-20">
          <h2 className="text-xl font-bold mb-4">Edit Payment Status</h2>
          <div className="mb-4">
            <label className="block mb-1 font-medium">Status</label>
            <select
              value={newStatus}
              onChange={(e) => setNewStatus(e.target.value)}
              className="border border-gray-300 p-2 rounded-md w-full"
            >
              <option value="pending">Pending</option>
              <option value="completed">Completed</option>
              <option value="failed">Failed</option>
            </select>
          </div>
          <div className="flex justify-between">
            <Button onClick={handleUpdateStatus} color="primary" variant="contained">Update Status</Button>
            <Button onClick={handleCloseModal} color="secondary" variant="outlined" className="ml-2">Cancel</Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SuperAdminPayments;
