import React, { useState } from "react";
import { motion } from "framer-motion"; // For animation
import './priceStyle.css';
import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";
import waimg from "../../../assets/images/whatsapp.png";
const Pricing = () => {
  const [focusedCard, setFocusedCard] = useState(null);

  const pricingPlans = [
    {
      role: "Supplier",
      price: "Monthly 1000PKR",
      features: [
        "Feature 1: View all details of a tender ",
        "Feature 2: Bid for Unlimited Tenders",
        "Feature 3: 24/7 support",
        "Feature 4: Archive Applied Tenders",
      ],
      color: "bg-gradient-to-r from-green-400 to-blue-500", // Gradient background
    },
    {
      role: "NGO",
      price: "800 Euro Annually",
      features: [
        "Feature 1: Create/Customize Tenders",
        "Feature 2: Manage Company Employee Accounts",
        "Feature 3: Update Bidding Rules",
        "Feature 4: Update Profiles",
      ],
      color: "bg-gradient-to-r from-purple-400 to-pink-600", // Gradient background
    },
    {
      role: "Donor",
      price: "1500 Euro Annual",
      features: [
        "Feature 1: Ability to view project status",
        "Feature 2: Access to NGO needs",
        "Feature 3: Direct donation to specific causes",
        "Feature 4: Tracking your donations",
      ],
      color: "bg-gradient-to-r from-yellow-400 to-red-500", // Gradient background
    },
  ];

  return (
    <div className="flex flex-col min-h-screen">
      {/* Navbar */}
     <Navbar />

      <div className="text-center mb-8 mt-10">
        <p className="text-3xl font-bold mb-4">Choose the Right Plan for You</p>
        <p className="text-lg text-gray-700 mb-6">
          Select the role that best suits your needs. Whether you're a Supplier, NGO, or Donor, we have the perfect tools and features for you.
        </p>
      </div>

      <div className="flex justify-center space-x-6 flex-wrap mb-10">
        {pricingPlans.map((plan, index) => (
          <motion.div
            key={index}
            className={`p-10 rounded-xl shadow-xl w-full max-w-sm cursor-pointer transition-all duration-300 ease-in-out transform ${
              focusedCard === index ? "scale-105 border-4 border-yellow-300" : "scale-100"
            } ${plan.color} mb-6`}
            onClick={() => setFocusedCard(index)}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <h3 className="text-3xl font-bold text-white text-center mb-4">{plan.role}</h3>
            <p className="text-xl font-semibold text-white text-center mb-4">{plan.price}</p>
            <ul className="list-disc pl-5 text-white">
              {plan.features.map((feature, idx) => (
                <li key={idx} className="mb-3">{feature}</li>
              ))}
            </ul>
          </motion.div>
        ))}
      </div>

      {/* Footer */}
            <Footer/>
             {/* WhatsApp Fixed Floating Button */}
      <a
        href="https://wa.me/1234567890"
        target="_blank"
        rel="noopener noreferrer"
        className="whatsapp-button"
      >
        <img src={waimg} alt="Chat with us" />
      </a>

    </div>
  );
};

export default Pricing;
