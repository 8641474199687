import React from 'react';
import Navbar from './navbar/Navbar';
import Footer from './footer/Footer';
import { motion } from 'framer-motion';
import waimg from "../../assets/images/whatsapp.png";

const AboutUs = () => {
  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      <Navbar />

      <main className="flex-grow flex flex-col items-center px-6 py-10">
        {/* Header */}
        <h1 className="text-4xl font-bold text-center mb-8 text-gray-800 md:text-5xl">
          About Bilcono – A Complete Solution for NGO Procurement
        </h1>

        {/* Introduction */}
        <motion.p 
          className="text-lg text-center max-w-3xl mb-6 text-gray-600 md:text-xl"
          initial={{ opacity: 0 }} 
          animate={{ opacity: 1 }} 
          transition={{ duration: 1 }}
        >
          Welcome to Bilcono, home of the revolutionary NGO Tenders Portal, a one-stop solution for NGOs, suppliers, and donors.
          Our platform is designed to bring all stakeholders in the procurement process onto a single, transparent, and user-friendly 
          platform, ensuring efficiency, accountability, and collaboration.
        </motion.p>

        {/* Section: Who We Are */}
        <motion.div 
          className="w-full max-w-full mb-8"
          initial={{ opacity: 0 }} 
          animate={{ opacity: 1 }} 
          transition={{ duration: 1, delay: 0.2 }}
        >
          <div className="bg-white shadow-lg rounded-lg p-6 hover:shadow-2xl transition-all duration-300">
            <h2 className="text-2xl font-semibold text-center mt-4 mb-4 text-gray-700">Who We Are</h2>
            <p className="text-lg mb-4 text-center text-gray-600">
              Bilcono is a pioneering software house committed to empowering the development sector with innovative digital tools.
            </p>
          </div>
        </motion.div>

        {/* Section: Our Mission */}
        <motion.div 
          className="w-full max-w-full mb-8"
          initial={{ opacity: 0 }} 
          animate={{ opacity: 1 }} 
          transition={{ duration: 1, delay: 0.4 }}
        >
          <div className="bg-white shadow-lg rounded-lg p-6 hover:shadow-2xl transition-all duration-300">
            <h2 className="text-2xl font-semibold text-center mt-4 mb-4 text-gray-700">Our Mission</h2>
            <p className="text-lg mb-4 text-center text-gray-600">
              To simplify and enhance the procurement process for NGOs, suppliers, and donors.
            </p>
          </div>
        </motion.div>

        {/* Section: A Complete Solution for All Stakeholders */}
        <motion.div 
          className="w-full max-w-full mb-8"
          initial={{ opacity: 0 }} 
          animate={{ opacity: 1 }} 
          transition={{ duration: 1, delay: 0.6 }}
        >
          <div className="bg-white shadow-lg rounded-lg p-6 hover:shadow-2xl transition-all duration-300">
            <h2 className="text-2xl font-semibold text-center mt-4 mb-4 text-gray-700">A Complete Solution for All Stakeholders</h2>
            <div className="text-center text-gray-600 space-y-4">
              <p className="text-lg"><strong>For Suppliers:</strong> Access tenders from all NGOs on one page.</p>
              <p className="text-lg"><strong>For NGOs:</strong> Discover and connect with a diverse range of verified suppliers.</p>
              <p className="text-lg"><strong>For Donors:</strong> Monitor the procurement process in real time.</p>
            </div>
          </div>
        </motion.div>

        {/* Section: Key Features */}
        <motion.div 
          className="w-full max-w-full mb-8"
          initial={{ opacity: 0 }} 
          animate={{ opacity: 1 }} 
          transition={{ duration: 1, delay: 0.8 }}
        >
          <div className="bg-white shadow-lg rounded-lg p-6 hover:shadow-2xl transition-all duration-300">
            <h2 className="text-2xl font-semibold text-center mt-4 mb-4 text-gray-700">Key Features of the NGO Tenders Portal</h2>
            <ul className="text-lg list-disc list-inside text-gray-600 space-y-3">
              <li>Tender Consolidation</li>
              <li>Supplier Directory</li>
              <li>Donor Monitoring</li>
              <li>Market Rates</li>
              <li>IFRC Specifications</li>
              <li>Transparent Processes</li>
            </ul>
          </div>
        </motion.div>

        {/* Section: Why Choose Us */}
        <motion.div 
          className="w-full max-w-full mb-8"
          initial={{ opacity: 0 }} 
          animate={{ opacity: 1 }} 
          transition={{ duration: 1, delay: 1 }}
        >
          <div className="bg-white shadow-lg rounded-lg p-6 hover:shadow-2xl transition-all duration-300">
            <h2 className="text-2xl font-semibold text-center mt-4 mb-4 text-gray-700">Why Choose Us?</h2>
            <p className="text-lg text-center text-gray-600">
              A unified platform for suppliers, NGOs, and donors to collaborate with transparency and accountability.
            </p>
          </div>
        </motion.div>

        {/* Section: Additional Services */}
        <motion.div 
          className="w-full max-w-full mb-8"
          initial={{ opacity: 0 }} 
          animate={{ opacity: 1 }} 
          transition={{ duration: 1, delay: 1.2 }}
        >
          <div className="bg-white shadow-lg rounded-lg p-6 hover:shadow-2xl transition-all duration-300">
            <h2 className="text-2xl font-semibold text-center mt-4 mb-4 text-gray-700">Additional Services</h2>
            <p className="text-lg text-center text-gray-600">
              Bilcono also provides NGOsJobs Portal, a dedicated platform for NGOs to post and find job opportunities within the development sector.
            </p>
          </div>
        </motion.div>

        {/* Contact Information */}
        <motion.div 
          className="w-full max-w-full mb-8"
          initial={{ opacity: 0 }} 
          animate={{ opacity: 1 }} 
          transition={{ duration: 1, delay: 1.4 }}
        >
          <div className="bg-white shadow-lg rounded-lg p-6 hover:shadow-2xl transition-all duration-300">
            <h2 className="text-2xl font-semibold text-center mt-4 mb-4 text-gray-700">Contact Us</h2>
            <div className="text-lg text-center text-gray-600 space-y-2">
              <p><strong>For Sales:</strong> sales@ngostenders.com</p>
              <p><strong>For Support:</strong> support@ngostenders.com</p>
              <p><strong>For 24/7 Support and Payment Queries:</strong> +92 312 1111 002</p>
            </div>
          </div>
        </motion.div>
      </main>

      <Footer />
       {/* WhatsApp Fixed Floating Button */}
       <a
        href="https://wa.me/1234567890"
        target="_blank"
        rel="noopener noreferrer"
        className="whatsapp-button"
      >
        <img src={waimg} alt="Chat with us" />
      </a>

    </div>
  );
};

export default AboutUs;
