import React, { useState, useEffect, useRef } from "react";
import Navbar from "./navbar/Navbar";
import Footer from "./footer/Footer";
import { useNavigate } from "react-router-dom";
import waimg from "../../assets/images/whatsapp.png";
import { FaEye } from "react-icons/fa";
import {
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  IconButton,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchedTenders from "./SearchedTendors";
import { handleAPIRequest } from "../../api/api";
import "./homepage.css"; // Ensure CSS for the glowing effects and animations
import { useSelector, useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dayjs from "dayjs";
const Homepage = () => {
  const { isAuthenticated, user, role } = useSelector((state) => state.auth);
  const [showTenders, setShowTenders] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("");
  const [error, setError] = useState({ searchOrFilter: false });
  const [latestTenders, setLatestTenders] = useState([]);
  const [featuredTenders, setFeaturedTenders] = useState([]);
  const mainContentRef = useRef(null);
  const featuredRef = useRef(null);
  const navigate = useNavigate();

  const handleSearch = () => {
    const hasValidInput = searchTerm.trim() !== "" || filter !== "";
    setError({ searchOrFilter: !hasValidInput });

    if (hasValidInput) {
      setShowTenders(true);
    } else {
      setShowTenders(false);
    }
  };

  const handleApplyNow = (tender) => {
    if (!isAuthenticated) {
      toast.error("Please login first to apply for this tender.");
      return;
    }
    navigate("/single-tender", { state: { tender } });
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  const fetchLatestTenders = async () => {
    try {
      const response = await handleAPIRequest("get", "latest-tenders", null);
      setLatestTenders(Array.isArray(response.tenders) ? response.tenders : []);
    } catch (error) {
      console.error("Error fetching latest tenders:", error);
      setLatestTenders([]);
    }
  };

  const fetchFeatuerdTenders = async () => {
    try {
      const response = await handleAPIRequest("get", "featured-tenders", null);
      setFeaturedTenders(
        Array.isArray(response.tenders) ? response.tenders : []
      );
    } catch (error) {
      console.error("Error fetching featured tenders:", error);
      setFeaturedTenders([]);
    }
  };

  useEffect(() => {
    fetchLatestTenders();
    fetchFeatuerdTenders();
  }, []);

  return (
    <div className="flex flex-col min-h-screen bg-gray-200">
      <Navbar />

      <main className="flex-grow flex flex-row items-start justify-center p-4 bg-gray shadow-lg rounded-lg">
        {/* Main Content - 80% width */}
        <div ref={mainContentRef} className="w-full md:w-4/5 p-6 bg-gray ">
          <div className="w-full max-w-4xl p-6 bg-gray-100 shadow-lg rounded-lg flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4">
            <TextField
              variant="outlined"
              placeholder="Search for Tenders..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="flex-grow"
              inputProps={{ className: "text-sm md:text-base" }}
            />
            <FormControl variant="outlined" className="w-full md:w-1/4">
              <InputLabel id="filter-label">Filter</InputLabel>
              <Select
                labelId="filter-label"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                label="Filter"
              >
                <MenuItem value="">None</MenuItem>
                <MenuItem value="Agriculture and Livelihood Supplies">
                  Agriculture and Livelihood Supplies
                </MenuItem>
                <MenuItem value="Construction and Infrastructure">
                  Construction and Infrastructure
                </MenuItem>
                <MenuItem value="Information Technology (IT) and Equipment">
                  Information Technology (IT) and Equipment
                </MenuItem>
                <MenuItem value="Healthcare and Medical Supplies">
                  Healthcare and Medical Supplies
                </MenuItem>
                <MenuItem value="Non-Food Items (NFI)">
                  Non-Food Items (NFI)
                </MenuItem>
                <MenuItem value="Food Supplies and Nutrition">
                  Food Supplies and Nutrition
                </MenuItem>
                <MenuItem value="Water, Sanitation, and Hygiene (WASH)">
                  Water, Sanitation, and Hygiene (WASH)
                </MenuItem>
                <MenuItem value="Education and Training Materials">
                  Education and Training Materials
                </MenuItem>
                <MenuItem value="Energy and Solar Solutions">
                  Energy and Solar Solutions
                </MenuItem>
                <MenuItem value="Transport and Vehicle Leasing">
                  Transport and Vehicle Leasing
                </MenuItem>
                <MenuItem value="Logistics and Warehousing">
                  Logistics and Warehousing
                </MenuItem>
                <MenuItem value="Consultancy and Research Services">
                  Consultancy and Research Services
                </MenuItem>
                <MenuItem value="Protective Equipment and Safety Gear">
                  Protective Equipment and Safety Gear
                </MenuItem>
                <MenuItem value="Communication and Media Services">
                  Communication and Media Services
                </MenuItem>
                <MenuItem value="Office Supplies and Furniture">
                  Office Supplies and Furniture
                </MenuItem>
              </Select>
            </FormControl>

            <div className="flex items-center space-x-2">
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={handleSearch}
                className="py-3 px-6"
              >
                Search
              </Button>

              <IconButton onClick={handleRefresh} color="primary" size="large">
                <RefreshIcon />
              </IconButton>
            </div>
          </div>

          {error.searchOrFilter && (
            <p className="text-red-500 text-sm mt-2">
              Please provide either a search term or select a filter.
            </p>
          )}

          <h2 className="text-3xl font-bold mt-10">
            {showTenders ? "Searched Results" : "Latest Tenders"}
          </h2>

          {showTenders ? (
            <SearchedTenders searchTerm={searchTerm} filter={filter} />
          ) : (
            <div className="w-full p-6 space-y-6 ">
              {latestTenders.map((tender) => (
                <div
                  key={tender.id}
                  className={`bg-white flex items-center p-4 rounded-lg shadow-lg hover:shadow-xl transition-all transform hover:scale-105 border ${
                    tender.featured === "yes" ? "border-gold-glow" : ""
                  }`}
                >
                  <img
                    src={tender.company_document.document_url}
                    alt={tender.tender_title}
                    className="w-32 h-32 object-cover rounded-md mr-6"
                  />
                  <div className="flex-grow">
                    <h3 className="text-xl font-bold">{tender.tender_title}</h3>
                    <div className="flex flex-wrap gap-2 mb-2">
                      <span className="text-sm text-white bg-green-500 px-2 py-1 rounded-md">
                        Start Date:{" "}
                        {dayjs(tender.tender_start_date).format("DD MMMM YYYY")}
                      </span>
                      <span className="text-sm text-white bg-green-500 px-2 py-1 rounded-md">
                        End Date:{" "}
                        {dayjs(tender.tender_end_date).format("DD MMMM YYYY")}
                      </span>
                      <span className="text-sm text-white bg-blue-500 px-2 py-1 rounded-md">
                        NGO: {tender.companies.company_name}
                      </span>
                    </div>
                    <span
                      className={`text-white text-sm px-2 py-1 rounded-md bg-gray-500`}
                    >
                      {tender.bid_method === "Online"
                        ? "Online Bid"
                        : "Manual Bid"}
                    </span>
                    <p className="mt-2">
                      {tender.tender_description.length > 100
                        ? `${tender.tender_description.slice(0, 100)}...`
                        : tender.tender_description}
                    </p>
                  </div>
                  <button
                    onClick={() => handleApplyNow(tender)}
                    className="mt-4 text-blue-600 hover:text-blue-700"
                  >
                    <FaEye size={24} />
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Featured Tenders - Full screen height with moving cards */}
        <div
          ref={featuredRef}
          className="w-full md:w-1/5 p-6 bg-gray overflow-hidden"
        >
          <h2 className="text-2xl font-bold mb-4 ">Featured Tenders</h2>
          <div className="featured-tenders-list h-full overflow-hidden">
            <ul
              className="featured-tenders-content animate-vertical-scroll"
              onMouseEnter={(e) =>
                (e.currentTarget.style.animationPlayState = "paused")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.animationPlayState = "running")
              }
            >
              {featuredTenders.map((tender) => (
                <li
                  key={tender.id}
                  className={`border-gold-glow p-6 mb-4 bg-white rounded-lg shadow-lg hover:shadow-xl transition-all`}
                >
                  <div>
                    <img
                      src={tender.company_document.document_url}
                      alt={tender.tender_title}
                      className="w-full h-40 object-cover rounded-md mb-4"
                    />
                    <h3 className="text-lg font-semibold">
                      {tender.tender_title}
                    </h3>
                    <div className="flex flex-wrap gap-2 mt-2">
                      <span className="text-sm text-white bg-green-500 px-2 py-1 rounded-md">
                        Start Date:{" "}
                        {dayjs(tender.tender_start_date).format("DD MMMM YYYY")}
                      </span>
                      <span className="text-sm text-white bg-green-500 px-2 py-1 rounded-md">
                        End Date:{" "}
                        {dayjs(tender.tender_end_date).format("DD MMMM YYYY")}
                      </span>
                      <span
                        className={`text-sm text-white px-2 py-1 rounded-md bg-gray-500 text-white`}
                      >
                        {tender.bid_method === "Online"
                          ? "Online Bid"
                          : "Manual Bid"}
                      </span>
                    </div>
                    <button
                      onClick={() => handleApplyNow(tender)}
                      className="mt-4 text-blue-600 hover:text-blue-700"
                    >
                      <FaEye size={24} />
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </main>

      <Footer />

      {/* WhatsApp Fixed Floating Button */}
      <a
        href="https://wa.me/1234567890"
        target="_blank"
        rel="noopener noreferrer"
        className="whatsapp-button"
      >
        <img src={waimg} alt="Chat with us" />
      </a>

      <ToastContainer />
    </div>
  );
};

export default Homepage;
