import React, { useState, useEffect } from "react";
import Navbar from "../Navbar"; // Import your Navbar component
import Footer from "../Footer"; // Import your Footer component
import { handleAPIRequest } from "../../../api/api"; // Make sure to import your API handling function
import { useSelector } from "react-redux";
import { AiFillEdit, AiFillDelete } from "react-icons/ai"; // Import icons from react-icons
import Swal from "sweetalert2"; // Import SweetAlert
import { toast , ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SuperAdminSidebar from "./superAdminSidebar";

const SuperAdminTender = () => {
  const [isOpen, setIsOpen] = useState(false); // For Add Tender modal
  const [isEditOpen, setIsEditOpen] = useState(false); // For Edit Tender modal
  const [tenders, setTenders] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  // const [bidMethodModal, setBidMethodModal] = useState(false);
  const [requiredDocuments, setRequiredDocuments] = useState([]);
  const [tendersPerPage] = useState(10); // Set items per page
  const [newTender, setNewTender] = useState({
    tender_title: "",
    tender_description: "",
    tender_category: "",
    item_category: "",
    tender_start_date: "",
    tender_end_date: "",
    tender_pack_url: "",
    tender_document: null,
    status: "open",
    featured: false, // Added featured property
  });
  const [selectedTender, setSelectedTender] = useState(null); // State for the selected tender
  const [selectedDocument, setSelectedDocument] = useState(null); // State for the selected document
  const token = useSelector((state) => state.auth.token); // Get the token from Redux state
  // const handleBidMethod = (method) => {
  //   // Placeholder for API call
  // };


// State for the modal
const [featuredModal, setFeaturedModal] = useState(false);
const [featuredStatus, setFeaturedStatus] = useState("");

// Function to open the featured status modal
const openFeaturedModal = (tender) => {
  setSelectedTender(tender);
  setFeaturedStatus(tender.featured);
  setFeaturedModal(true);
};

// Function to handle the featured status update
const handleFeaturedUpdate = async () => {
  try {
    const formData = {
      type: featuredStatus, // 'yes' or 'no'
      tenderId: selectedTender?.id,
    };

    const response = await handleAPIRequest(
      "post",
      "update-feature-status",
      formData,
      null,
      token
    );

    if (response?.message === "Tender featured status updated successfully") {
      window.location.reload();
      toast.success("Featured status updated successfully");
      // Update the tender list or refresh data if necessary
    } else {
     
      toast.error("Failed to update featured status");
    }
  } catch (error) {
    toast.error("Error updating featured status:", error);
  } finally {
    setFeaturedModal(false);
  }
};
  const toggleDocument = (document) => {
    setRequiredDocuments((prev) =>
      prev.includes(document)
        ? prev.filter((doc) => doc !== document)
        : [...prev, document]
    );
  };

  // const openBidMethodModal = (tender) => {
  //   setSelectedTender(tender);
  //   setRequiredDocuments(tender.required_documents || []);
  //   setBidMethodModal(true);
  // };
  const toggleModal = () => setIsOpen(!isOpen);
  const toggleEditModal = () => {
    setIsEditOpen(!isEditOpen);
    if (isEditOpen) {
      setSelectedTender(null); // Clear selected tender on close
    }
  };
  const toggleDocumentModal = () => setSelectedDocument(null); // Close document modal
  const handleDocumentDownload = (documentUrl) => {
    // Create an anchor tag to trigger the download
    const link = document.createElement("a");
    link.href = documentUrl;
    link.download = documentUrl.split("/").pop(); // Extract the file name for download
    link.click();
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewTender((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    setNewTender((prev) => ({ ...prev, tender_document: e.target.files[0] }));
  };

  // const handleAddTender = async (e) => {
  //   e.preventDefault();

  //   // Prepare FormData
  //   const formData = new FormData();
  //   formData.append("tender_title", newTender.tender_title);
  //   formData.append("tender_description", newTender.tender_description);
  //   formData.append("tender_category", newTender.tender_category);
  //   formData.append("item_category", newTender.item_category);
  //   formData.append("tender_start_date", newTender.tender_start_date);
  //   formData.append("tender_end_date", newTender.tender_end_date);
  //   formData.append("status", newTender.status);
  //   formData.append("tender_doc", newTender.tender_document); // Use correct name for document
  //   formData.append("tender_tag", newTender.tender_tag); // Ensure to set the tender tag

  //   try {
  //     // Call the API to create a new tender
  //     const response = await handleAPIRequest(
  //       "post",
  //       "create-tender",
  //       formData,
  //       null,
  //       token
  //     );

  //     if (response) {
  //       Swal.fire("Success!", "Tender added successfully.", "success").then(
  //         () => {
  //           // Reload the page to refresh the tenders
  //           window.location.reload();
  //         }
  //       );
  //     } else {
  //       // Show API error message
  //       Swal.fire(
  //         "Error!",
  //         response.message || "There was a problem adding the tender.",
  //         "error"
  //       );
  //     }
  //   } catch (error) {
  //     Swal.fire("Error!", "There was a problem adding the tender.", "error");
  //   }
  // };

  const handleEditTender = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();

      // Map each form field to the required API fields
      formData.append("tender_title", newTender.tender_title); // Tender Title
      formData.append("tender_description", newTender.tender_description || ""); // Tender Description
      formData.append("tender_category", newTender.tender_category); // Tender Category
      formData.append("item_category", newTender.item_category); // Item Category
      formData.append("tender_start_date", newTender.tender_start_date); // Start Date
      formData.append("tender_end_date", newTender.tender_end_date); // End Date
      formData.append("status", newTender.status); // Status
      formData.append("tender_tag", newTender.documents.document_tag); // Document Tag

      // Conditionally add the file if a new file is selected
      if (newTender.tender_doc) {
        formData.append("tender_doc", newTender.tender_document); // Document File
      }

      // Send the edited tender to the API
      const response = await handleAPIRequest(
        "post",
        `edit-tender/${selectedTender.id}`,
        formData,
        null,
        token
      );
      console.log('te',response)
      if (response) {
        // Update the tender in the state
        setTenders((prev) =>
          prev.map((tender) =>
            tender.id === selectedTender.id
              ? { ...selectedTender, ...newTender }
              : tender
          )
        );

        // Clear modal form fields
        setNewTender({
          tender_title: "",
          tender_description: "",
          tender_category: "",
          item_category: "",
          tender_start_date: "",
          tender_end_date: "",
          tender_doc: null,
          tender_tag: "",
          status: "open",
        });

        toggleEditModal(); // Close modal

        // Show success notification
        toast.success("Tender updated successfully!");
      }
    } catch (error) {
      console.error("Error updating tender:", error);
      toast.error("Failed to update tender. Please try again.");
    }
  };

  const handleDeleteTender = async (id) => {
    // SweetAlert for confirmation
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      // Prepare form data
      const formData = new FormData();
      formData.append("tender_id", id); // Add tender_id to FormData

      try {
        // Send the delete request to the API
        const response = await handleAPIRequest(
          "post",
          "tender-delete",
          formData,
          null,
          token
        );

        if (response.message === "Tender deleted successfully.") {
          // Handle success response
          setTenders((prev) => prev.filter((tender) => tender.id !== id));
          Swal.fire("Deleted!", "Your tender has been deleted.", "success");
        } else {
          // Handle error response
          Swal.fire(
            "Error!",
            "There was a problem deleting the tender.",
            "error"
          );
        }
      } catch (error) {
        // Handle error if the API request fails
        Swal.fire(
          "Error!",
          "There was a problem deleting the tender.",
          "error"
        );
      }
    }
  };

  // Fetch company tenders on component mount
  useEffect(() => {
    const fetchTenders = async () => {
      try {
        const response = await handleAPIRequest(
          "get",
          "get-all-tenders",
          null,
          null,
          token
        );
        console.log(response); // Log response to console
        if (response) {
          setTenders(response.tenders); // Update state with fetched tenders
        }
      } catch (error) {
        console.error("Error fetching tenders:", error);
      }
    };

    fetchTenders();
  }, [token]);

  const fetchTenderDetails = async (id) => {
    try {
      const response = await handleAPIRequest(
        "get",
        `get-tender/${id}`,
        null,
        null,
        token
      );
      if (response) {
        setNewTender(response.tender); // Update state with fetched tender details
        setSelectedTender(response.tender);
        toggleEditModal(); // Open edit modal
      }
    } catch (error) {
      console.error("Error fetching tender details:", error);
    }
  };

  // Search functionality
  const filteredTenders = tenders.filter(
    (tender) =>
      tender.tender_title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      tender.tender_description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Pagination logic
  const indexOfLastTender = currentPage * tendersPerPage;
  const indexOfFirstTender = indexOfLastTender - tendersPerPage;
  const currentTenders = filteredTenders.slice(
    indexOfFirstTender,
    indexOfLastTender
  );
  const totalPages = Math.ceil(filteredTenders.length / tendersPerPage);

  return (
    <div className="flex min-h-screen flex-col">
      <div className="flex flex-1">
        <SuperAdminSidebar />

        <div className="flex-1 flex flex-col">
          <Navbar />

          <div className="p-8 flex-1">
            <div className="mb-8">
              <h1 className="text-3xl font-bold mb-2">Company Tenders</h1>
              <p className="text-gray-600">
                Manage all company tenders here. You can view, add, edit, or
                delete tenders.
              </p>
            </div>

            <div className="flex justify-between mb-6">
              <input
                type="text"
                placeholder="Search Tenders..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="border border-gray-300 p-2 rounded-md w-full max-w-md"
              />
              {/* <button
                onClick={toggleModal}
                className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition duration-200 ml-4"
              >
                Add Tender
              </button> */}
            </div>

            <div className="overflow-x-auto">
        <table className="min-w-full border border-gray-300">
          <thead className="bg-gray-200">
            <tr>
              <th className="py-2 px-4 border">Tender ID</th>
              <th className="py-2 px-4 border">Title</th>
              <th className="py-2 px-4 border">Description</th>
              <th className="py-2 px-4 border">Item Category</th>
              <th className="py-2 px-4 border">Tender Category</th>
              <th className="py-2 px-4 border">Start Date</th>
              <th className="py-2 px-4 border">End Date</th>
              <th className="py-2 px-4 border">Document</th>
              <th className="py-2 px-4 border">Status</th>
              <th className="py-2 px-4 border">Featured</th>
              <th className="py-2 px-4 border">Bid Method</th>
              <th className="py-2 px-4 border">Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentTenders.map((tender) => (
              <tr key={tender.id} className="hover:bg-gray-100">
                <td className="py-2 px-4 border">{tender.id}</td>
                <td className="py-2 px-4 border">{tender.tender_title}</td>
                <td className="py-2 px-4 border"> {tender.tender_description.length > 100
                        ? `${tender.tender_description.slice(0, 100)}...`
                        : tender.tender_description}</td>
                <td className="py-2 px-4 border">{tender.item_category}</td>
                <td className="py-2 px-4 border">{tender.tender_category}</td>
                <td className="py-2 px-4 border">{new Date(tender.tender_start_date).toLocaleDateString()}</td>
                <td className="py-2 px-4 border">{new Date(tender.tender_end_date).toLocaleDateString()}</td>
                <td className="py-2 px-4 border">
              <button
                onClick={() => handleDocumentDownload(tender.documents.document_url)}
                className="text-blue-500 hover:underline"
              >
                View Document
              </button>
            </td>
                <td className="py-2 px-4 border">{tender.status}</td>
                <td
      className="py-2 px-4 border cursor-pointer"
      onClick={() => openFeaturedModal(tender)}
    >
      {tender.featured === "yes" ? (
        <span className="inline-block px-2 py-1 rounded-full text-white bg-green-500">
          Yes
        </span>
      ) : (
        <span className="inline-block px-2 py-1 rounded-full text-white bg-red-500">
          No
        </span>
      )}
    </td>
                <td className="py-2 px-4 border">
                 
                    {tender.bid_method}
            
                </td>
                <td className="py-2 px-4 border">
                  <AiFillEdit
                    className="inline-block text-blue-600 cursor-pointer text-2xl"
                    onClick={() => fetchTenderDetails(tender.id)}
                  />
                  <AiFillDelete
                    className="inline-block text-red-600 cursor-pointer ml-2 text-2xl"
                    onClick={() => handleDeleteTender(tender.id)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      <div className="mt-4 flex justify-between items-center">
        <div>
          <span>Page {currentPage} of {totalPages}</span>
        </div>
        <div>
          <button
            disabled={currentPage === 1}
            onClick={() => setCurrentPage(currentPage - 1)}
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 transition duration-200"
          >
            Previous
          </button>
          <button
            disabled={currentPage === totalPages}
            onClick={() => setCurrentPage(currentPage + 1)}
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 transition duration-200 ml-2"
          >
            Next
          </button>
        </div>
      </div>
          </div>

          <Footer />
        </div>
      </div>

 {/* Feature Update Modal */}

 {featuredModal && (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
        <div className="bg-white p-6 rounded-lg w-1/3">
          <h3 className="text-lg font-semibold mb-4">Update Featured Status</h3>

          <label className="block mb-4">
            Set Featured Status:
            <select
              value={featuredStatus}
              onChange={(e) => setFeaturedStatus(e.target.value)}
              className="block w-full p-2 border border-gray-300 rounded mt-1"
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </label>

          <div className="mt-6 flex justify-end">
            <button
              onClick={() => setFeaturedModal(false)}
              className="px-4 py-2 bg-gray-400 text-white rounded hover:bg-gray-500 transition"
            >
              Cancel
            </button>
            <button
              onClick={handleFeaturedUpdate}
              className="ml-2 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    )}
      {/* Modal for Adding Tender */}
{/* {isOpen && (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
    <div className="bg-white p-8 rounded-md shadow-lg w-full max-w-3xl max-h-[90vh] overflow-y-auto">
      <h2 className="text-xl font-bold mb-4">Add Tender</h2>
      <form onSubmit={handleAddTender}>
        <div className="gap-4">
          <div className="w-full mb-4">
            <label className="block mb-1 font-medium">Tender Title</label>
            <input
              type="text"
              name="tender_title"
              value={newTender.tender_title}
              onChange={handleChange}
              className="border border-gray-300 p-2 rounded-md w-full"
              required
            />
          </div>

          
          <div className="w-full mb-4">
            <label className="block mb-1 font-medium">Tender Category</label>
            <select
              name="tender_category"
              value={newTender.tender_category}
              onChange={handleChange}
              className="border border-gray-300 p-2 rounded-md w-full"
              required
            >
              <option value="">Select a category</option>
              <option value="Open Tender">Open Tender</option>
              <option value="Restricted Tender">Restricted Tender</option>
              <option value="Framework Agreement Tender">Framework Agreement Tender</option>
              <option value="Request for Quotation (RFQ)">Request for Quotation (RFQ)</option>
              <option value="Request for Proposal (RFP)">Request for Proposal (RFP)</option>
              <option value="Request for Information (RFI)">Request for Information (RFI)</option>
              <option value="Two-Stage Tender">Two-Stage Tender</option>
              <option value="Auction Notice">Auction Notice</option>
            </select>
          </div>

         
          <div className="w-full mb-4">
            <label className="block mb-1 font-medium">Item Category</label>
            <select
              name="item_category"
              value={newTender.item_category}
              onChange={handleChange}
              className="border border-gray-300 p-2 rounded-md w-full"
              required
            >
              <option value="">Select an item category</option>
              <option value="Agriculture and Livelihood Supplies">Agriculture and Livelihood Supplies</option>
              <option value="Construction and Infrastructure">Construction and Infrastructure</option>
              <option value="Information Technology (IT) and Equipment">Information Technology (IT) and Equipment</option>
              <option value="Healthcare and Medical Supplies">Healthcare and Medical Supplies</option>
              <option value="Non-Food Items (NFI)">Non-Food Items (NFI)</option>
              <option value="Food Supplies and Nutrition">Food Supplies and Nutrition</option>
              <option value="Water, Sanitation, and Hygiene (WASH)">Water, Sanitation, and Hygiene (WASH)</option>
              <option value="Education and Training Materials">Education and Training Materials</option>
              <option value="Energy and Solar Solutions">Energy and Solar Solutions</option>
              <option value="Transport and Vehicle Leasing">Transport and Vehicle Leasing</option>
              <option value="Logistics and Warehousing">Logistics and Warehousing</option>
              <option value="Consultancy and Research Services">Consultancy and Research Services</option>
              <option value="Protective Equipment and Safety Gear">Protective Equipment and Safety Gear</option>
              <option value="Communication and Media Services">Communication and Media Services</option>
              <option value="Office Supplies and Furniture">Office Supplies and Furniture</option>
            </select>
          </div>

         
          <div className="gap-4 mt-4">
            <div className="w-full mb-4">
              <label className="block mb-1 font-medium">Start Date</label>
              <input
                type="date"
                name="tender_start_date"
                value={newTender.tender_start_date ? newTender.tender_start_date.slice(0, 16) : ''}
                onChange={handleChange}
                min={new Date().toISOString().split("T")[0]} // Prevent past dates
                className="border border-gray-300 p-2 rounded-md w-full"
                required
              />
            </div>
            <div className="w-full mb-4">
              <label className="block mb-1 font-medium">End Date</label>
              <input
                type="date"
                name="tender_end_date"
                value={newTender.tender_end_date}
                onChange={handleChange}
                min={newTender.tender_start_date} // Set min to start date
                className="border border-gray-300 p-2 rounded-md w-full"
                required
              />
            </div>
          </div>

         
          <div className="w-full mb-4">
            <label className="block mb-1 font-medium">Status</label>
            <select
              name="status"
              value={newTender.status}
              onChange={handleChange}
              className="border border-gray-300 p-2 rounded-md w-full"
            >
              <option value="open">Open</option>
              <option value="closed">Closed</option>
              <option value="awarded">Awarded</option>
              <option value="canceled">Canceled</option>
            </select>
          </div>

          <div className="w-full mb-4">
            <label className="block mb-1 font-medium">Choose File</label>
            <input
              type="file"
              name="tender_file"
              onChange={handleFileChange}
              className="border border-gray-300 p-2 rounded-md w-full"
            />
          </div>

   
          <div className="w-full mb-4">
            <label className="block mb-1 font-medium">Tender Description</label>
            <textarea
              name="tender_description"
              value={newTender.tender_description}
              onChange={handleChange}
              className="border border-gray-300 p-2 rounded-md w-full"
              required
            />
          </div>
          
         
          <div className="mt-6 flex justify-end">
            <button
              type="submit"
              className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition duration-200"
            >
              Add Tender
            </button>
            <button
              type="button"
              onClick={toggleModal}
              className="ml-2 bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400 transition duration-200"
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
)} */}


   {/* Modal for Editing Tender */}
   {isEditOpen && selectedTender && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded-md shadow-lg w-full max-w-3xl max-h-[90vh] overflow-y-auto">
            <h2 className="text-xl font-bold mb-4">Edit Tender</h2>
            <form onSubmit={handleEditTender}>
              <div className="gap-4">
                <div className="w-full mb-4">
                  <label className="block mb-1 font-medium">Tender Title</label>
                  <input
                    type="text"
                    name="tender_title"
                    value={newTender.tender_title}
                    onChange={handleChange}
                    className="border border-gray-300 p-2 rounded-md w-full"
                    required
                  />
                </div>

                {/* Dropdown for Tender Category */}
                <div className="w-full mb-4">
                  <label className="block mb-1 font-medium">
                    Tender Category
                  </label>
                  <select
                    name="tender_category"
                    value={newTender.tender_category}
                    onChange={handleChange}
                    className="border border-gray-300 p-2 rounded-md w-full"
                    required
                  >
                    <option value="">Select a category</option>
                    <option value="Open Tender">Open Tender</option>
                    <option value="Restricted Tender">Restricted Tender</option>
                    <option value="Framework Agreement Tender">
                      Framework Agreement Tender
                    </option>
                    <option value="Request for Quotation (RFQ)">
                      Request for Quotation (RFQ)
                    </option>
                    <option value="Request for Proposal (RFP)">
                      Request for Proposal (RFP)
                    </option>
                    <option value="Request for Information (RFI)">
                      Request for Information (RFI)
                    </option>
                    <option value="Two-Stage Tender">Two-Stage Tender</option>
                    <option value="Auction Notice">Auction Notice</option>
                  </select>
                </div>

                {/* Dropdown for Item Category */}
                <div className="w-full mb-4">
                  <label className="block mb-1 font-medium">
                    Item Category
                  </label>
                  <select
                    name="item_category"
                    value={newTender.item_category}
                    onChange={handleChange}
                    className="border border-gray-300 p-2 rounded-md w-full"
                    required
                  >
                    <option value="">Select an item category</option>
                    <option value="Agriculture and Livelihood Supplies">
                      Agriculture and Livelihood Supplies
                    </option>
                    <option value="Construction and Infrastructure">
                      Construction and Infrastructure
                    </option>
                    <option value="Information Technology (IT) and Equipment">
                      Information Technology (IT) and Equipment
                    </option>
                    <option value="Healthcare and Medical Supplies">
                      Healthcare and Medical Supplies
                    </option>
                    <option value="Non-Food Items (NFI)">
                      Non-Food Items (NFI)
                    </option>
                    <option value="Food Supplies and Nutrition">
                      Food Supplies and Nutrition
                    </option>
                    <option value="Water, Sanitation, and Hygiene (WASH)">
                      Water, Sanitation, and Hygiene (WASH)
                    </option>
                    <option value="Education and Training Materials">
                      Education and Training Materials
                    </option>
                    <option value="Energy and Solar Solutions">
                      Energy and Solar Solutions
                    </option>
                    <option value="Transport and Vehicle Leasing">
                      Transport and Vehicle Leasing
                    </option>
                    <option value="Logistics and Warehousing">
                      Logistics and Warehousing
                    </option>
                    <option value="Consultancy and Research Services">
                      Consultancy and Research Services
                    </option>
                    <option value="Protective Equipment and Safety Gear">
                      Protective Equipment and Safety Gear
                    </option>
                    <option value="Communication and Media Services">
                      Communication and Media Services
                    </option>
                    <option value="Office Supplies and Furniture">
                      Office Supplies and Furniture
                    </option>
                  </select>
                </div>

                {/* Start Date and End Date */}
                <div className="gap-4 mt-4">
                  <div className="w-full mb-4">
                    <label className="block mb-1 font-medium">Start Date</label>
                    <input
                      type="datetime-local"
                      name="tender_start_date"
                      value={
                        newTender.tender_start_date
                          ? newTender.tender_start_date.slice(0, 16)
                          : new Date().toISOString().slice(0, 16)
                      } // Default to current date and time
                      onChange={(e) => {
                        const selectedDate = e.target.value;
                        setNewTender((prev) => ({
                          ...prev,
                          tender_start_date: selectedDate,
                          tender_end_date:
                            selectedDate < prev.tender_end_date
                              ? prev.tender_end_date
                              : "", // Reset end date if not greater
                        }));
                      }}
                      className="border border-gray-300 p-2 rounded-md w-full"
                      required
                    />
                  </div>
                  <div className="w-full mb-4">
                    <label className="block mb-1 font-medium">End Date</label>
                    <input
                      type="datetime-local"
                      name="tender_end_date"
                      value={
                        newTender.tender_end_date
                          ? newTender.tender_end_date.slice(0, 16)
                          : ""
                      }
                      onChange={(e) => {
                        const selectedDate = e.target.value;
                        if (selectedDate <= newTender.tender_start_date) {
                          alert("End date must be greater than start date");
                        } else {
                          setNewTender((prev) => ({
                            ...prev,
                            tender_end_date: selectedDate,
                          }));
                        }
                      }}
                      className="border border-gray-300 p-2 rounded-md w-full"
                      required
                    />
                  </div>
                </div>

                {/* Status */}
                <div className="w-full mb-4">
                  <label className="block mb-1 font-medium">Status</label>
                  <select
                    name="status"
                    value={newTender.status}
                    onChange={handleChange}
                    className="border border-gray-300 p-2 rounded-md w-full"
                  >
                    <option value="open">Open</option>
                    <option value="closed">Closed</option>
                    <option value="awarded">Awarded</option>
                    <option value="canceled">Canceled</option>
                  </select>
                </div>
               {/*
                <div className="w-full mb-4">
                  <label className="block mb-1 font-medium">Document Tag</label>
                  <input
                    type="text"
                    name="tender_tag"
                    value={newTender.documents.document_tag}
                    onChange={handleChange}
                    className="border border-gray-300 p-2 rounded-md w-full"
                    required
                  />
                </div>
                
                <div className="w-full mb-4">
                  <img
                    src={newTender.documents.document_url}
                    className="h-20 w-20"
                  />
                  <label className="block mb-1 font-medium">Choose File</label>
                  <input
                    type="file"
                    name="tender_file"
                    onChange={handleFileChange}
                    className="border border-gray-300 p-2 rounded-md w-full"
                  />
                </div>

               */}
                {/* Description */}
                <div className="w-full mb-4">
                  <label className="block mb-1 font-medium">
                    Tender Description
                  </label>
                  <textarea
                    name="tender_description"
                    value={newTender.tender_description}
                    onChange={handleChange}
                    className="border border-gray-300 p-2 rounded-md w-full"
                    required
                  />
                </div>

                {/* Buttons */}
                <div className="mt-6 flex justify-end">
                  <button
                    type="submit"
                    className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition duration-200"
                  >
                    Update Tender
                  </button>
                  <button
                    type="button"
                    onClick={toggleEditModal}
                    className="ml-2 bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400 transition duration-200"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}


      {/* Document Modal */}
      {selectedDocument && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded-md shadow-lg w-full max-w-2xl">
            <h2 className="text-xl font-bold mb-4">Tender Document</h2>
            <iframe
              src={selectedDocument}
              title="Tender Document"
              className="w-full h-96"
            />
            <div className="mt-6 flex justify-end">
              <button
                type="button"
                onClick={toggleDocumentModal}
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400 transition duration-200"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default SuperAdminTender;
