import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import Navbar from "../Navbar";
import Sidebar from "./donorSidebar";
import Footer from "../Footer";
import { handleAPIRequest } from "../../../api/api";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DonorPeople = () => {
  const [users, setUsers] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [newStatus, setNewStatus] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(10);
  const token = useSelector((state) => state.auth.token);

  const fetchUsers = async () => {
    try {
      const response = await handleAPIRequest(
        "get",
        "get-all-users",
        null,
        null,
        token
      );
      if (response && response.status) {
        setUsers(response.users);
      } else {
        Swal.fire(
          "Error",
          response.message || "Failed to fetch users data",
          "error"
        );
      }
    } catch (error) {
      console.error("Error fetching users:", error);
      Swal.fire(
        "Error",
        "An error occurred while fetching users data",
        "error"
      );
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [token]);

  const filteredUsers = users.filter((user) => {
    const searchString = searchTerm.toLowerCase();
    return (
      user.name.toLowerCase().includes(searchString) ||
      user.email.toLowerCase().includes(searchString) ||
      user.contact_no.toLowerCase().includes(searchString) ||
      user.status.toLowerCase().includes(searchString) ||
      user.roles.some((role) => role.toLowerCase().includes(searchString))
    );
  });

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

  return (
    <div className="flex h-screen">
      <Sidebar className="h-full" />
      <div className="flex flex-col w-full">
        <Navbar />
        <div className="container mx-auto p-4">
          <h2 className="text-2xl font-bold mb-4">Super Admin People</h2>
          <div className="mb-4 flex justify-between items-center">
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="border border-gray-300 p-2 rounded-md w-full"
            />
          </div>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-300">
              <thead>
                <tr>
                  <th className="py-2 px-4 border-b">ID</th>
                  <th className="py-2 px-4 border-b">Name</th>
                  <th className="py-2 px-4 border-b">Email</th>
                  <th className="py-2 px-4 border-b">Contact No</th>
                  <th className="py-2 px-4 border-b">Role</th>
                  <th className="py-2 px-4 border-b">Status</th>
                </tr>
              </thead>
              <tbody>
                {currentUsers.map((user) => (
                  <tr
                    key={user.id}
                    className={
                      user.status === "suspended" ? "bg-yellow-100" : ""
                    }
                  >
                    <td className="py-2 px-4 border-b">{user.id}</td>
                    <td className="py-2 px-4 border-b">{user.name}</td>
                    <td className="py-2 px-4 border-b">{user.email}</td>
                    <td className="py-2 px-4 border-b">{user.contact_no}</td>
                    <td className="py-2 px-4 border-b">
                      {user.roles
                        .map((role) => {
                          // Role mapping logic
                          switch (role) {
                            case "super_admin":
                              return "Super Admin";
                            case "admin":
                              return "Admin";
                            case "company_owner":
                              return "NGO";
                            case "company_user":
                              return "NGO Employee";
                            case "donor_owner":
                              return "Donor";
                            case "supplier_owner":
                              return "Supplier";
                            default:
                              return role; // In case of an unknown role
                          }
                        })
                        .join(", ")}
                    </td>
                    <td className="py-2 px-4 border-b">
                      <button>
                        {user.status === "active" ? (
                          <span className="inline-block px-2 py-1 rounded-full text-white bg-green-500">
                            Active
                          </span>
                        ) : user.status === "suspended" ? (
                          <span className="inline-block px-2 py-1 rounded-full text-black bg-yellow-400">
                            Suspended
                          </span>
                        ) : (
                          <span className="inline-block px-2 py-1 rounded-full text-white bg-red-500">
                            Inactive
                          </span>
                        )}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="flex justify-between items-center mt-4">
            <button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              className="px-4 py-2 bg-gray-200 rounded disabled:opacity-50"
            >
              Previous
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() =>
                setCurrentPage((prev) => Math.min(prev + 1, totalPages))
              }
              disabled={currentPage === totalPages}
              className="px-4 py-2 bg-gray-200 rounded disabled:opacity-50"
            >
              Next
            </button>
          </div>
        </div>

        {/* Edit User Modal */}
        {/* {isEditModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-4 rounded shadow-md w-1/3">
              <h2 className="text-xl font-semibold mb-4">Edit User</h2>
              <form onSubmit={handleEditUser}>
                <div className="mb-4">
                  <label className="block font-medium">Name:</label>
                  <input
                    type="text"
                    name="name"
                    defaultValue={selectedUser.name}
                    className="border border-gray-300 p-2 w-full rounded"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block font-medium">Email:</label>
                  <input
                    type="email"
                    name="email"
                    defaultValue={selectedUser.email}
                    className="border border-gray-300 p-2 w-full rounded"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block font-medium">Contact Number:</label>
                  <input
                    type="text"
                    name="contact_no"
                    defaultValue={selectedUser.contact_no}
                    className="border border-gray-300 p-2 w-full rounded"
                  />
                </div>
                <div className="mb-4">
                  <label className="block font-medium">Password:</label>
                  <input
                    type="password"
                    name="password"
                    className="border border-gray-300 p-2 w-full rounded"
                    placeholder="Enter new password if you wish to change"
                  />
                </div>
                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={() => setIsEditModalOpen(false)}
                    className="px-4 py-2 mr-2 bg-gray-300 rounded"
                  >
                    Cancel
                  </button>
                  <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        )} */}

        {/* Status Modal */}
        {/* {isStatusModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-4 rounded shadow-md w-1/3">
              <h2 className="text-xl font-semibold mb-4">Update Status</h2>
              <select
                value={newStatus}
                onChange={(e) => setNewStatus(e.target.value)}
                className="border border-gray-300 p-2 w-full rounded mb-4"
              >
                <option value="active">Active</option>
                <option value="suspended">Suspended</option>
                <option value="inactive">Inactive</option>
              </select>
              <div className="flex justify-end">
                <button
                  onClick={() => setIsStatusModalOpen(false)}
                  className="px-4 py-2 mr-2 bg-gray-300 rounded"
                >
                  Cancel
                </button>
                <button
                  onClick={handleUpdateStatus}
                  className="px-4 py-2 bg-blue-500 text-white rounded"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        )} */}

        <ToastContainer />
        <Footer />
      </div>
    </div>
  );
};

export default DonorPeople;
