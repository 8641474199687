// src/components/dashboard/Supplier/SupplierDashboard.jsx
import React from 'react';
import Navbar from '../Navbar'; // Adjust the import path if necessary
import SupplierSidebar from './supplierSidebar';
import Footer from '../Footer'; // Adjust the import path if necessary

const SupplierDashboard = () => {
  return (
    <div className="flex min-h-screen">
      <SupplierSidebar />
      <div className="flex-1 flex flex-col">
        <Navbar />
        <main className="flex-grow p-6">
          <h2 className="text-2xl font-bold mb-4">Supplier Dashboard</h2>
          <p>Welcome to the Supplier Dashboard! Manage your Supplier details, tenders, and bids here.</p>
          {/* Add more specific content/components here */}
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default SupplierDashboard;
