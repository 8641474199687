import React from 'react';
import { handleAPIRequest } from "../../../api/api"; // Adjust the import to your actual file structure
import { toast,ToastContainer } from 'react-toastify'; // Ensure you have react-toastify installed
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for the toast notifications
import { useNavigate } from 'react-router-dom';
const StepConfirmation = ({ userInfo, documents, prevStep, nextStep, tag , amount }) => {
  const navigate = useNavigate();
  const {
    name,
    phone,
    email,
    password,
    address,
    country,
    role,
    companyDetails,
    supplierDetails,
    donorDetails,
  } = userInfo;

  const handleSubmit = async () => {
    // Create a FormData object
    const formData = new FormData();
    
    // Append basic user info
    formData.append('email', email);
    formData.append('password', password);
    formData.append('password_confirmation', password);
    formData.append('contact_no', phone);
    formData.append('contact_person', name);
    formData.append('user_address', address);
    formData.append('country', country);
    formData.append('registrationType', role);
    // Append role-specific fields
    if (role === 'company') {
      formData.append('company_name', companyDetails.companyName);
      formData.append('company_registration_no', companyDetails.regNo);
      formData.append('company_address', companyDetails.address);
      formData.append('company_website', companyDetails.website);
    } else if (role === 'supplier') {
      formData.append('supplier_name', supplierDetails.supplierName);
      formData.append('supplier_registration_no', supplierDetails.regNo);
      formData.append('supplier_address', supplierDetails.address);
      formData.append('supplier_website', supplierDetails.website);
    } else if (role === 'donor') {
      formData.append('donor_name', donorDetails.donorName);
      formData.append('donor_address', donorDetails.address);
      formData.append('donor_website', donorDetails.website);
    }

    // Append documents and tag
    if (documents.length > 0) {
      documents.forEach((doc) => {
        formData.append('payment_doc', doc); // Assuming doc is a File object
      });
    }
    formData.append('amount' ,amount)
    formData.append('payment_tag', tag);
   
    try {
      // Call your handleAPIRequest function
      const response = await handleAPIRequest('post', 'register', formData);

      if (response) {
        toast.success('Signup successful!');
        setTimeout(() => {
          navigate('/login'); // Redirect to login
        }, 2000); 
      }
    } catch (error) {

        toast.error('An error occurred. Please try again later.');
    }
  };

  return (
    <div>
      <h3 className="text-2xl font-semibold mb-2">Confirm Your Information</h3>

      <div className="mb-2">
        <h4 className="font-semibold">Personal Information</h4>
        <p><strong>Name:</strong> {name}</p>
        <p><strong>Phone Number:</strong> {phone}</p>
        <p><strong>Email:</strong> {email}</p>
        <p><strong>Address:</strong> {address}</p>
        <p><strong>Country:</strong> {country}</p>
      </div>

      <div className="mb-2">
        <h4 className="font-semibold"> {role} Information</h4>
        {role === 'company' && (
          <div>
            <p><strong>Company Name:</strong> {companyDetails.companyName}</p>
            <p><strong>Registration Number:</strong> {companyDetails.regNo}</p>
            <p><strong>Company Address:</strong> {companyDetails.address}</p>
            <p><strong>Website:</strong> {companyDetails.website}</p>
          </div>
        )}

        {role === 'supplier' && (
          <div>
            <p><strong>Supplier Name:</strong> {supplierDetails.supplierName}</p>
            <p><strong>Registration Number:</strong> {supplierDetails.regNo}</p>
            <p><strong>Supplier Address:</strong> {supplierDetails.address}</p>
          </div>
        )}

        {role === 'donor' && (
          <div>
            <p><strong>Donor Name:</strong> {donorDetails.donorName}</p>
            <p><strong>Donor Address:</strong> {donorDetails.address}</p>
            <p><strong>Website:</strong> {donorDetails.website}</p>
          </div>
        )}
      </div>

      <div className="mb-2">
        <h4 className="font-semibold">Documents</h4>
        {documents.length > 0 ? (
          documents.map((doc, index) => (
            <p key={index}><strong>Document Name:</strong> {doc.name}</p>
          ))
        ) : (
          <p>No documents uploaded.</p>
        )}
        <p><strong>Amount:</strong> {amount || 'no Amount Added'}</p>
        <p><strong>Tag:</strong> {tag || 'No tag added.'}</p>
      </div>

      <div className="flex justify-between mt-2">
        <button
          onClick={prevStep}
          className="bg-green-500 text-white px-4 py-2 rounded mr-4"
        >
          Back
        </button>
        <button
          onClick={handleSubmit}
          className="bg-green-500 text-white px-4 py-2 rounded"
        >
          Submit
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default StepConfirmation;
